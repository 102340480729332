import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBAlert
} from "mdbreact";

import QrReader from 'react-qr-reader'


import UserCardActions from "./actions/UserCardActions";

import moment from 'moment';
import 'moment/locale/de';


class UserCardForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      nid: false,
      field_customer: this.props.searchComponent.props.customerId,
      field_user: this.props.uid,
      field_card_secret_key: '',
      loaded: true,
      modal: false,
    }


    this._onSubmit = this._onSubmit.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
  }

  _onHandleScan = data => {
    if (data) {
      this.setState({
        field_card_secret_key: data,
      })
    }
  }

  _onHandleError = err => {
    console.error(err)
  }


  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    component.props.searchComponent.setState(prevState => ({
      loaded: false,
    }))

    let values = {
      nid: this.state.nid,
      field_user: this.state.field_user,
      field_customer: this.state.field_customer,
      field_card_secret_key: this.state.field_card_secret_key,
    }

    if(!this.state.nid) {
      UserCardActions._post(values)
        .then(response => {
          component.props.searchComponent.loadUserCardCollection();
          component._onFormReset();
          component._onToggleModal();

          if(component.state.field_customer) {
            component.props.searchComponent.props.customerDetail._loadCustomer();
          }
        });
    } else {
      UserCardActions._patch(values)
        .then(response => {



          component.props.searchComponent.loadUserCardCollection();
          component._onFormReset();
          component._onToggleModal();

          if(component.state.field_customer) {
            component.props.searchComponent.props.customerDetail._loadCustomer();
          }

        });
    }
  }

  _onFormReset() {
    this.setState(prevState => ({
      loaded: true,
      field_card_secret_key: '',
      nid: false
    }))
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    let component = this;
    return (

      <>
      <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neue Karte erfassen</MDBBtn>
      <MDBModal isOpen={this.state.modal} toggle={this._onToggleModal}>
        <MDBModalHeader toggle={this._onToggleModal}>Neue Karte erfassen</MDBModalHeader>
        <MDBModalBody>
          <MDBCard className='mt-0'>
            <form id="usercard-add-form" onSubmit={this._onSubmit}>
              <MDBRow>
                <MDBCol size="12">
                  {!this.state.field_card_secret_key &&
                    <QrReader
                    className="qr-scanner"
                    delay={500}
                    facingMode={'front'}
                    onError={this._onHandleError}
                    onScan={this._onHandleScan}
                    style={{ width: '100%' }}
                    />
                  }

                  {this.state.field_card_secret_key &&
                    <MDBAlert color="success" >
                      {'Kundenkarte wurde beschrieben. Bitte speichern'}
                    </MDBAlert>
                  }



                </MDBCol>
                <MDBCol size="12">

               <hr />

                 <MDBRow>
                   <MDBCol>


                       {!this.state.field_card_secret_key &&
                         <MDBBtn color="primary" type="submit" disabled><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                       }

                       {this.state.field_card_secret_key &&
                         <MDBBtn color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                       }

                   </MDBCol>
                 </MDBRow>

                </MDBCol>
              </MDBRow>
            </form>
          </MDBCard>
        </MDBModalBody>
      </MDBModal>
      </>


    );
  }
}

export default UserCardForm;
