import React, { Component } from 'react';

/** import config **/
import config from '../../../config';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
} from 'mdbreact';



import VacationQuotaSearch from "./VacationQuotaSearch";



/** import styles **/
import '../../../assets/css/sections/vacationquota.css';


class VacationQuotaOverview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    }

    this.sectionRef = React.createRef();
  }

  componentDidMount() {
  }

  render() {
    return (
      <section id="vacationquota-overview" ref={this.sectionRef}>
        <MDBCard className="card-vacationquota">
          <MDBCardBody>
            <MDBCardTitle><MDBIcon far icon="address-card mr-2" /> Ferien Kontignente</MDBCardTitle>
            <MDBCardText tag="div">
              <VacationQuotaSearch overviewComponent={this} />
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>
      </section>
    );
  }
}

export default VacationQuotaOverview;
