import React, { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBDatePicker,
  MDBFileInput,
  MDBSelect,
  MDBIcon,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBAnimation,
  MDBNavLink,
  MDBCardText,
  MDBLink,
  MDBCollapseHeader,
  MDBCollapse,
  MDBDatePickerV5
} from 'mdbreact';
import VacationActions from './actions/VacationActions';
import { history } from '../../../helpers/history';

import Select from 'react-select';


class VacationForm extends Component {

  constructor(props) {
    super(props);


    var employeeOptions = this.props.searchComponent.state.employeeFilter;
    var vacationCategoryOptions = this.props.searchComponent.state.vacationCategoryFilter;

    this.state = {
      hasSubmitted: false,
      modal: false,
      nid: false,
      field_employee: [],
      field_employee_all: false,
      field_start: null,
      field_end: null,
      field_body: '',
      field_quota: '',
      field_vacation_category: [],
      employeeOptions: employeeOptions,
      vacationCategoryOptions: vacationCategoryOptions
    }


    this.employeeSelectRef = React.createRef();
    this.categorySelectRef = React.createRef();

    this._onToggleModal = this._onToggleModal.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onGetPickerValue = this._onGetPickerValue.bind(this);
    this._onClickDelete = this._onClickDelete.bind(this);
    this._onCheckboxChange = this._onCheckboxChange.bind(this);
  }

  componentDidMount() {
    let component = this;
    //console.log('componentDidMount');
  }


  _onToggleModal = () => {

    var component = this;

    var employeeOptions = component.props.searchComponent.state.employeeFilter;
    component.setState({
      employeeOptions: employeeOptions
    });

    var component = this;
    this.setState({
      modal: !this.state.modal
    }, component._onToggleModalCallback);
  }

  _onToggleModalCallback = () => {
    var component = this;

    if(this.state.modal) {
        var employeeOptions = component.props.searchComponent.state.employeeFilter;
        component.setState({
          employeeOptions: employeeOptions
        }, function(e) {
          //component._setSelects();
        });

    } else {
      component._onFormReset();
    }
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }

    //console.log(this);

    this.setState({
      [e.target.name]: [e.target.value.value],
    });
  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();


    if(component.state.hasSubmitted) {
      return;
    }

    this.setState(prevState => ({
      loaded: false,
      hasSubmitted: true,
    }))




    let values = {
      nid: this.state.nid,
      field_employee: this.state.field_employee,
      field_start: this.state.field_start,
      field_end: this.state.field_end,
      field_body: this.state.field_body,
      field_quota: this.state.field_quota,
      field_employee_all: this.state.field_employee_all,
      field_vacation_category: this.state.field_vacation_category
    }


    if(!this.state.nid) {
      VacationActions._post(values)
        .then(response => {


          component.setState(prevState => ({
            hasSubmitted: false,
          }))



          if(response.data && response.data.status == 200) {
            component.props.searchComponent.loadVacationCollection();
            component._onFormReset();
            component._onToggleModal();
          }
        });
    } else {
      VacationActions._patch(values)
        .then(response => {


          component.setState(prevState => ({
            hasSubmitted: false,
          }))


          if(response.data && response.data.status == 200) {
            component.props.searchComponent.loadVacationCollection();
            component._onFormReset();
            component._onToggleModal();
          }
        });
    }
  }

  _onInputChange(e) {
    //console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });

    //console.log(this.state);
  }


  _onFormReset() {

    var component = this;

    this.setState(prevState => ({
      hasSubmitted: false,
      loaded: true,
      nid: false,
      field_employee: [],
      field_employee_all: false,
      field_start: '',
      field_end: '',
      field_body: '',
      field_quota: '',
      field_vacation_category: '',
    }))


    if(component.employeeSelectRef.current) {
      component.employeeSelectRef.current.select.clearValue();
    }

    if(component.categorySelectRef.current) {
      component.categorySelectRef.current.select.clearValue();
    }


  }

  _onGetPickerValue = (value, name) => {
    //console.log(value);
    //console.log(name);
    //var date = new Date(value).toJSON();
    this.setState({
      [name]: value
    });
  }

  _onClickDelete(e) {


    //console.log('_onClickDelete');
    var component = this;
    VacationActions._delete(this.state.nid)
      .then(response => {
        if(response.data.status == 400) {
          alert(response.data.message);
        } else {
          component.props.searchComponent.loadVacationCollection();
          component._onToggleModal();
          component._onFormReset();
        }
      });

  }



  _onCheckboxChange(e) {
    var name = e.target.name;
    var value = e.target.value;


    this.setState({
        [name]: value,
    });

  }

  render() {
    let component = this;


    var defaultEmployeeValue = null;

    if(component.state.employeeOptions.find(x => x.value === component.state.field_employee[0])) {
      var selectedItem = { label: component.state.employeeOptions.find(x => x.value ===(component.state.field_employee[0])).label, value: (component.state.field_employee[0]) };
      defaultEmployeeValue = selectedItem;
    }


    var defaultCategoryValue = null;

    //console.log(component.state.vacationCategoryOptions);

    if(component.state.vacationCategoryOptions.find(x => x.value === component.state.field_vacation_category[0])) {
      var selectedItem = { label: component.state.vacationCategoryOptions.find(x => x.value ===(component.state.field_vacation_category[0])).label, value: (component.state.field_vacation_category[0]) };
      defaultCategoryValue = selectedItem;
    }

    //console.log(defaultCategoryValue);





    return (
      <>

        <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neuen Urlaub erfassen</MDBBtn>


        <MDBModal isOpen={this.state.modal} toggle={this._onToggleModal} size="lg">
          <MDBModalHeader toggle={this._onToggleModal}>Urlaubserfassung</MDBModalHeader>
          <MDBModalBody>
            <MDBCard className='mt-0'>

              <form onSubmit={this._onSubmit} id="vacation-add-form">


                {!this.state.field_employee_all && !this.state.nid &&
                  <div className="row">
                    <div className="col-sm-12">

                      <div class="md-form form-group md-outline">

                      <Select
                        ref={this.employeeSelectRef}
                        required
                        defaultValue={defaultEmployeeValue}
                        className="react-select"
                        classNamePrefix="react-select"
                        placeholder="Mitarbeiter"
                        options={this.props.searchComponent.state.employeeFilter}
                        isClearable={true}
                        onChange={(value) => {

                          //console.log(value);

                          if(value) {
                            value = value;
                          } else {
                            value = '';
                          }

                          this._onSelectChange({
                            target: {
                              name: 'field_employee',
                              value: value,
                            },
                          });
                        }}
                      />
                    </div>
                    </div>
                  </div>
                }


                {!this.state.nid &&
                <div className="row">
                  <div className="col-sm-12">
                    <MDBInput outline label="Für Alle Mitarbeiter" type="checkbox" id="checkall" name="field_employee_all" onChange={(e) => {
                        this._onCheckboxChange({
                          target: {
                            name: e.target.name,
                            value: e.target.checked,
                          },
                        });
                      }} checked={this.state.field_employee_all} />
                  </div>
                </div>
                }

                <div className="row">
                  <div className="col-sm-12">

                    <div class="md-form form-group md-outline">
                    <Select
                      ref={this.categorySelectRef}
                      required
                      defaultValue={defaultCategoryValue}
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder="Kategorie"
                      options={this.props.searchComponent.state.vacationCategoryFilter}
                      isClearable={true}
                      onChange={(value) => {

                        //console.log(value);

                        if(value) {
                          value = value;
                        } else {
                          value = '';
                        }

                        this._onSelectChange({
                          target: {
                            name: 'field_vacation_category',
                            value: value,
                          },
                        });
                      }}
                    />
                  </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">

                    <MDBInput outline type="date" id="field_start" label="Von" placeholder="Von" name="field_start" value={this.state.field_start} onChange={this._onInputChange} required />

                  </div>
                  <div className="col-sm-6">

                    <MDBInput outline type="date" id="field_end" label="Bis" placeholder="Bis" name="field_end" value={this.state.field_end} onChange={this._onInputChange} required />



                  </div>
                </div>


                {this.state.field_vacation_category[0] && this.state.field_vacation_category[0] == 67 &&
                  <div className="row">
                    <div className="col-sm-12">
                      <MDBInput outline required type="number" label="Tage" name="field_quota" value={this.state.field_quota} onChange={this._onInputChange} />
                    </div>
                  </div>
                }


                <div className="row">
                  <div className="col-sm-12">
                    <MDBInput outline type="textarea" label="Bemerkung" name="field_body" value={this.state.field_body} onChange={this._onInputChange} rows="5" />
                  </div>
                </div>

                <MDBRow>
                  <MDBCol>

                    <div className="form-action-bar">
                      {!this.state.nid &&
                        <MDBBtn color="danger" onClick={this._onFormReset} className="mr-2"><MDBIcon icon="sync mr-2" /> Zurücksetzen</MDBBtn>
                      }
                      {this.state.nid &&
                        <MDBBtn color="danger" onClick={this._onClickDelete} className="mr-2"><MDBIcon icon="times mr-2" /> löschen</MDBBtn>
                      }

                      <MDBBtn color="primary" disabled={( this.state.hasSubmitted  ? true : false)} type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                    </div>


                  </MDBCol>
                </MDBRow>

              </form>

            </MDBCard>
          </MDBModalBody>
        </MDBModal>
      </>
    );
  }
}

export default VacationForm;
