import React, { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBDatePicker,
  MDBFileInput,
  MDBSelect,
  MDBIcon,
  MDBSpinner,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBAnimation,
  MDBNavLink,
  MDBCardText,
  MDBLink,
  MDBCollapseHeader,
  MDBCollapse
} from 'mdbreact';
import EmployeeActions from './actions/EmployeeActions';
import { history } from '../../../helpers/history';

import MDBFileupload from 'mdb-react-fileupload';

import Image from "../image/Image";

import PasswordWithGenerator from 'react-password-with-generator';

import Select from 'react-select'



class EmployeeForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      uid: false,
      entity: this.props.entity,
      locationsFilter: this.props.searchComponent.state.locationsFilter,
      rolesFilter: this.props.searchComponent.state.rolesFilter,
      loaded: true,
      username: '',
      password: '',
      mail: '',
      roles: [],
      field_firstname: '',
      field_lastname: '',
      field_birthday: '',
      field_contract_start: '',
      field_contract_end: '',
      field_street: '',
      field_zip: '',
      field_city: '',
      field_phone: '',
      field_mobile: '',
      field_image: '',
      field_location: [],
    }

    this.locationFilterRef = React.createRef();
    this.rolesFilterRef = React.createRef();
    this.fileInputRef = React.createRef();

    this._onToggleModal = this._onToggleModal.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onChangeHandleFile = this._onChangeHandleFile.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onGetPickerValue = this._onGetPickerValue.bind(this);
  }

  componentDidMount() {

    let component = this;

    if(this.props.entity) {

      var selectedLocations = new Array();
      Object.entries(this.props.locations).forEach(function(item, key){
        selectedLocations.push(item[1].nid[0].value.toString());
      })
      var locations = this.props.searchComponent.state.locations;
      var locationsFilter = new Array();
      if(locations) {
        Object.entries(locations).forEach(function(item, key){
          var optionItem = {
            label: item[1].title[0].value,
            value: item[0]
          }
          locationsFilter.push(optionItem);
          //console.log(item);
        });
      }



      var roles = this.props.searchComponent.state.roles;
      var selectedRoles = new Array();
      Object.entries(this.props.roles).forEach(function(item, key){
        //console.log(item );
        selectedRoles.push(item[1]);
      })
      var rolesFilter = new Array();
      if(roles) {
        Object.entries(roles).forEach(function(item, key){
          var hasRole = component.props.roles.includes(item[1].id);
          var optionItem = {
            label: item[1].label,
            value: item[1].id
          }
          rolesFilter.push(optionItem);
          //console.log(item);
        });
      }



      this.setState(prevState => ({
        uid: this.props.entity.uid[0].value,
        username: this.props.entity.name[0].value,
        mail: this.props.entity.mail[0].value,
        field_firstname: this.props.entity.field_firstname[0].value,
        field_lastname: this.props.entity.field_lastname[0].value,
        field_birthday: this.props.entity.field_birthday[0] ? this.props.entity.field_birthday[0].value : null,
        field_contract_start: this.props.entity.field_contract_start[0] ? this.props.entity.field_contract_start[0].value : '',
        field_contract_end: this.props.entity.field_contract_end[0] ? this.props.entity.field_contract_end[0].value : '',
        field_street: this.props.entity.field_street[0].value,
        field_zip: this.props.entity.field_zip[0].value,
        field_city: this.props.entity.field_city[0].value,
        field_phone: this.props.entity.field_phone[0] ? this.props.entity.field_phone[0].value : '',
        field_mobile: this.props.entity.field_mobile[0] ? this.props.entity.field_mobile[0].value : '',
        field_location: selectedLocations,
        locationsFilter: locationsFilter,
        rolesFilter: rolesFilter,
        roles: selectedRoles
      }))
    }


  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onSelectChange(e) {

    //console.log(e);

    var name = e.target.name;
    var value;
    if(e.target.value) {
      value = e.target.value;
    } else {
      value = null;
    }

    if(Array.isArray(value)) {

      var valueArray = new Array();
      Object.entries(value).forEach(function(item, key){
        valueArray.push(item[1].value);
        //console.log(item);
      });

      this.setState({
        [e.target.name]: valueArray,
      });

    } else {
      this.setState({
        [e.target.name]: [e.target.value],
      });
    }


  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();



    this.setState(prevState => ({
      loaded: false,
    }))

    let values = {
      uid: this.state.uid,
      username: this.state.username,
      password: this.state.password,
      mail: this.state.mail,
      roles: this.state.roles,
      field_firstname: this.state.field_firstname,
      field_lastname: this.state.field_lastname,
      field_birthday: this.state.field_birthday,
      field_contract_start: this.state.field_contract_start,
      field_contract_end: this.state.field_contract_end,
      field_street: this.state.field_street,
      field_zip: this.state.field_zip,
      field_city: this.state.field_city,
      field_phone: this.state.field_phone,
      field_mobile: this.state.field_mobile,
      field_image: this.state.field_image,
      field_location: this.state.field_location,
    }

    if(!this.state.uid) {
      EmployeeActions._post(values)
        .then(response => {
          if(response.data.status == 400) {
            alert(response.data.message);
          } else {
            component.props.searchComponent.loadEmployeeCollection();
            component._onFormReset();
            component._onToggleModal();
          }
        });
    } else {
      EmployeeActions._patch(values)
        .then(response => {
          if(response.data.status == 400) {
            alert(response.data.message);
          } else {
            component.props.searchComponent.loadEmployeeCollection();
            component._onToggleModal();
          }
        });
    }
  }

  _onInputChange(e) {
    //console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });

    //console.log(this.state);
  }

  _onChangeHandleFile = (file) => {
    var component = this;
    var reader = new FileReader();
    reader.addEventListener("load", function () {
         var dataString = reader.result;
         var uint8 = new Uint8Array(dataString);
         //console.log(uint8);
         EmployeeActions._uploadImage(uint8).then(response => {
           component.setState({
             field_image: response.data.fid[0].value
           });
         });
    }, false);

    if(file) {
      reader.readAsArrayBuffer(file);
      this.setState({
        imageUrl: URL.createObjectURL(file)
      });
    }
  }

  _onFormReset() {

    if(this.rolesFilterRef.current) {
      this.rolesFilterRef.current.select.clearValue();
    }

    if(this.locationFilterRef.current) {
      this.locationFilterRef.current.select.clearValue();
    }

    if(this.fileInputRef.current) {
      this.fileInputRef.current.resetPreview();
    }

    var roles = this.props.searchComponent.state.roles;
    var rolesFilter = new Array();
    if(roles) {
      Object.entries(roles).forEach(function(item, key){
        var optionItem = {
          label: item[1].label,
          value: item[1].id
        }
        rolesFilter.push(optionItem);
      });
    }

    var locations = this.props.searchComponent.state.locations;
    var locationsFilter = new Array();
    if(locations) {
      Object.entries(locations).forEach(function(item, key){
        var optionItem = {
          label: item[1].title[0].value,
          value: item[0]
        }
        locationsFilter.push(optionItem);
      });
    }


    this.setState(prevState => ({
      loaded: true,
      rolesFilter: rolesFilter,
      locationsFilter: locationsFilter,
      uid: false,
      username: '',
      password: '',
      mail: '',
      roles: [],
      field_firstname: '',
      field_lastname: '',
      field_birthday: '',
      field_contract_start: '',
      field_contract_end: '',
      field_street: '',
      field_zip: '',
      field_city: '',
      field_phone: '',
      field_mobile: '',
      field_location: []
    }))
  }

  _onGetPickerValue = (name, value) => {
    //console.log(value);
    //var date = new Date(value).toJSON();
    this.setState({
      [name]: value
    });
  }

  render() {
    let component = this;

    var defaultRoleValue = new Array();
    var defaultLocationValue = new Array();

    if(this.state.uid) {
      Object.entries(component.props.roles).forEach(function(item, key){
        //console.log(item);
        if(component.props.searchComponent.state.rolesFilter.find(x => x.value === item[1])) {
          var selectedItem = { label: component.props.searchComponent.state.rolesFilter.find(x => x.value === item[1]).label, value: item[1] };
          defaultRoleValue.push(selectedItem);
        }
      })

      Object.entries(component.props.locations).forEach(function(item, key){
        //console.log(item);
        if(component.props.searchComponent.state.locationsFilter.find(x => x.value === (item[1].nid[0].value).toString())) {
          var selectedItem = { label: component.props.searchComponent.state.locationsFilter.find(x => x.value === (item[1].nid[0].value).toString()).label, value: (item[1].nid[0].value).toString() };
          defaultLocationValue.push(selectedItem);
        }
      })
    }



    return (
      <>
      {!this.state.entity &&
        <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neuen Mitarbeiter erfassen</MDBBtn>
      }

      {this.state.entity &&
        <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
      }


      <MDBModal isOpen={this.state.modal} toggle={this._onToggleModal} size="lg">
        <MDBModalHeader toggle={this._onToggleModal}>Mitarbeiterverwaltung</MDBModalHeader>
        <MDBModalBody>
          <MDBCard className='mt-0'>


            <form onSubmit={this._onSubmit} id="employee-add-form">


              <MDBRow>
                <MDBCol size="12" className="hide">
                    <MDBFileupload
                        maxFileSize="16M"
                        ref={this.fileInputRef}
                        getValue={this._onChangeHandleFile}
                        />

                </MDBCol>
                <MDBCol size="12">

              <MDBRow>
                <MDBCol>
                  <MDBInput
  outline                   name='field_firstname'
                    label='Vorname'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_firstname}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
  outline                   name='field_lastname'
                    label='Name'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_lastname}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>

                    <MDBInput outline type="date" id="field_birthday" label="Geburtstag" placeholder="Geburtstag" name="field_birthday" value={this.state.field_birthday} onChange={this._onInputChange} required />

                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol>

                  <MDBInput outline type="date" id="field_contract_start" label="Vertragsstart" placeholder="Vertragsstart" name="field_contract_start" value={this.state.field_contract_start} onChange={this._onInputChange} required />



                </MDBCol>
                <MDBCol>

                  <MDBInput outline type="date" id="field_contract_end" label="Vertragsende" placeholder="Vertragsende" name="field_contract_end" value={this.state.field_contract_end} onChange={this._onInputChange} />



                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol>
                  <MDBInput
  outline                   name='field_street'
                    label='Strasse'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_street}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
  outline                   name='field_zip'
                    label='PLZ'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_zip}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
  outline                   name='field_city'
                    label='Ort'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_city}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
              </MDBRow>


              <MDBRow>
                <MDBCol>
                  <MDBInput
  outline                   name='mail'
                    label='E-Mail'
                    group
                    type='email'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.mail}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
  outline                   name='field_mobile'
                    label='Mobil'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.field_mobile}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>
                  <MDBInput
  outline                   name='field_phone'
                    label='Telefon'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    value={this.state.field_phone}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
              </MDBRow>

              <MDBRow>
                <MDBCol className="text-left">

                  <div class="md-form form-group md-outline">
                  <Select
                    ref={this.rolesFilterRef}
                    menuContainerStyle={{'zIndex': 999}}
                    required
                    isMulti
                    className="react-select"
                    classNamePrefix="react-select"
                    placeholder="Rolle"
                    defaultValue={defaultRoleValue}
                    options={this.state.rolesFilter}
                    isClearable={true}
                    onChange={(value) => {

                      if(value) {
                        value = value;
                      } else {
                        value = '';
                      }


                      this._onSelectChange({
                        target: {
                          name: 'roles',
                          value: value,
                        },
                      });
                    }}
                  />
              </div>


                </MDBCol>
                <MDBCol className="text-left">

                  <div class="md-form form-group md-outline">

                  <Select
                    ref={this.locationFilterRef}
                    required
                    isMulti
                    defaultValue={defaultLocationValue}
                    className="react-select"
                    classNamePrefix="react-select"
                    placeholder="Standort"
                    options={this.state.locationsFilter}
                    isClearable={true}
                    onChange={(value) => {

                      //console.log(value);

                      if(value) {
                        value = value;
                      } else {
                        value = '';
                      }

                      this._onSelectChange({
                        target: {
                          name: 'field_location',
                          value: value,
                        },
                      });
                    }}
                  />
              </div>


                </MDBCol>
              </MDBRow>


              <MDBRow>
                <MDBCol>
                  <MDBInput
  outline                   name='username'
                    label='Benutzername'
                    group
                    type='text'
                    error='wrong'
                    success='right'
                    required
                    value={this.state.username}
                    onChange={this._onInputChange}
                  />
                </MDBCol>
                <MDBCol>



                  {/*
                  {!this.state.entity &&
                    <div className="md-form form-group">
                      <label style={{ fontSize: "1rem", transform: "translateY(-14px) scale(0.8)", position: "absolute", top: "0px", left: "0px", color: "#4285f4" }}>Passwort</label>
                      <PasswordWithGenerator name='password' onBlur={this._onInputChange} onFocus={this._onInputChange} className="" required />
                    </div>
                  }

                  {this.state.entity &&
                    <div className="md-form form-group">
                      <label style={{ fontSize: "1rem", transform: "translateY(-14px) scale(0.8)", position: "absolute", top: "0px", left: "0px", color: "#4285f4" }}>Passwort</label>
                      <PasswordWithGenerator name='password' onBlur={this._onInputChange} onFocus={this._onInputChange} readOnly className="" />
                    </div>
                  }
                  */}



                  {!this.state.entity &&
                        <MDBInput
  outline                         name='password'
                          label='Passwort'
                          group
                          type='password'
                          error='wrong'
                          success='right'
                          required
                          value={this.state.password}
                          onChange={this._onInputChange}
                        />
                  }

                  {this.state.entity &&
                        <MDBInput
  outline                         name='password'
                          label='Passwort'
                          group
                          type='password'
                          error='wrong'
                          success='right'
                          value={this.state.password}
                          onChange={this._onInputChange}
                        />
                  }






                </MDBCol>
              </MDBRow>




                <MDBRow>
                  <MDBCol>
                    <div className="form-action-bar">
                      {!this.state.entity &&
                        <MDBBtn color="danger" onClick={this._onFormReset} className="mr-2"><MDBIcon icon="sync mr-2" /> Zurücksetzen</MDBBtn>
                      }
                      <MDBBtn color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                    </div>
                  </MDBCol>
                </MDBRow>

            </MDBCol>
          </MDBRow>

            </form>

          </MDBCard>
        </MDBModalBody>
      </MDBModal>

      </>


    );
  }
}

export default EmployeeForm;
