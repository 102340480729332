import React, { Component } from 'react';

/** import config **/
import config from '../../../config';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBRow,
  MDBCol
} from 'mdbreact';

import NewsSearch from "../news/NewsSearch";

import TaskSearch from "../task/TaskSearch";


import EmployeeBirthday from '../employee/EmployeeBirthday';


/** import styles **/
import '../../../assets/css/sections/dashboard.css';



class Dashboard extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }




  render() {
    return (
      <section id="dashboard">



        <MDBCard className="card-locations">
          <MDBCardBody>
            <MDBCardTitle><MDBIcon icon="newspaper mr-2" /> News</MDBCardTitle>
            <MDBCardText tag="div">
              <NewsSearch />
            </MDBCardText>
          </MDBCardBody>
        </MDBCard>


        <EmployeeBirthday />



      </section>
    );
  }
}

export default Dashboard;
