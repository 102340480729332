import React from 'react';
import { Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';

import Dashboard from './modules/dashboard/Dashboard';

import CustomerOverview from './modules/customer/CustomerOverview';
import CustomerDetail from './modules/customer/CustomerDetail';

import TreatmentDetail from './modules/treatment/TreatmentDetail';

import EmployeeOverview from './modules/employee/EmployeeOverview';

import VacationQuotaOverview from './modules/vacation_quota/VacationQuotaOverview';

import LocationOverview from './modules/location/LocationOverview';

import NewsOverview from './modules/news/NewsOverview';

import DownloadOverview from './modules/download/DownloadOverview';

import FaqOverview from './modules/faq/FaqOverview';

import VacationOverview from './modules/vacation/VacationOverview';

import TaskOverview from './modules/task/TaskOverview';

import TaskTeamOverview from './modules/task/TaskOverview';

class Routes extends React.Component {
  render() {
    return (
      <>
        <PrivateRoute path='/' exact component={Dashboard} />
        <PrivateRoute path='/dashboard' exact component={Dashboard} />

        <PrivateRoute path='/customer' exact component={CustomerOverview} />
        <PrivateRoute path='/customer/:id' exact component={CustomerDetail} />

        <PrivateRoute path='/treatment/:id' exact component={TreatmentDetail} />

        <PrivateRoute path='/employee' exact component={EmployeeOverview} />

        <PrivateRoute path='/news' exact component={NewsOverview} />

        <PrivateRoute path='/location' exact component={LocationOverview} />

        <PrivateRoute path='/download' exact component={DownloadOverview} />

        <PrivateRoute path='/faq' exact component={FaqOverview} />

        <PrivateRoute path='/vacation' exact component={VacationOverview} />

        <PrivateRoute path='/vacationquota' exact component={VacationQuotaOverview} />

        <PrivateRoute path='/task' exact component={TaskOverview} />
        <PrivateRoute path='/taskteam' exact component={TaskTeamOverview} />

      </>
    );
  }
}

export default Routes;
