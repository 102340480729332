import React, {
  Component
} from "react";

import {DebounceInput} from 'react-debounce-input';


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBBtn
} from 'mdbreact';

class VacationQuotaFilter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      employeeOptions: this.props.employeeOptions,
    }

    this._onCheckboxChange = this._onCheckboxChange.bind(this);
    this._onSelectChange = this._onSelectChange.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onDirectionChange = this._onDirectionChange.bind(this);
  }

  componentDidMount() {
    this.setState(prevState => ({
        loaded: true
    }));
  }

  _onCheckboxChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.component.state.filters;
    filters[name] = value;


    this.props.component.setState({
        filters: filters,
        page: 0
    }, () => {
        this.props.component.loadVacationQuotaCollection();
    });

  }

  _onSelectChange(e) {

    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        this.props.searchComponent.loadVacationQuotaCollection();
    });
  }

  _onDirectionChange(e) {
    var name = e.target.name;
    var value = e.target.value;

    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;

    this.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        this.props.searchComponent.loadVacationQuotaCollection();
    });
  }


  _onInputChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        this.props.searchComponent.loadVacationQuotaCollection();
    });
  }

  render() {
    let component = this;
    return (
      <div className="filter-card">

        <MDBRow>




          <MDBCol md="3" xs="12">
            <MDBSelect
                required
                options={this.props.searchComponent.state.yearOptions}
                selected="Jahr"
                label="Jahr"
                id="field_year"
                name="field_year"
                getValue={(e) => {
                  this._onSelectChange({
                    target: {
                      name: 'field_year',
                      value: e,
                    },
                  });
                }}
            />
          </MDBCol>

          <MDBCol md="3" xs="12">
            <MDBSelect
                required
                options={this.props.searchComponent.state.employeeFilter}
                selected="Mitarbeiter"
                label="Mitarbeiter"
                id="field_employee"
                name="field_employee"
                getValue={(e) => {
                  this._onSelectChange({
                    target: {
                      name: 'field_employee',
                      value: e,
                    },
                  });
                }}
            />
          </MDBCol>



          {/*
            <MDBCol md="3" xs="12" className="d-flex col-sort">
              <MDBSelect
                  options={this.props.searchComponent.state.sortOptions}
                  selected="Sortierung"
                  label="Sortierung"
                  id="field_sort"
                  name="field_sort"
                  getValue={(e) => {
                    this._onSelectChange({
                      target: {
                        name: 'field_sort',
                        value: e,
                      },
                    });
                  }}
              />

            <MDBBtn flat size="lg" color="primary" onClick={(e) => {
                this._onDirectionChange({
                  target: {
                    name: 'field_sort_direction',
                    value: (this.props.searchComponent.state.filters.field_sort_direction == 'asc') ? "desc" : "asc",
                  },
                });
            }}>
            {this.props.searchComponent.state.filters.field_sort_direction == 'asc' &&
              <MDBIcon icon="sort-alpha-down-alt" />
            }

            {this.props.searchComponent.state.filters.field_sort_direction == 'desc' &&
              <MDBIcon icon="sort-alpha-up-alt" />
            }

            </MDBBtn>

            </MDBCol>*/
          }
        </MDBRow>

      </div>
    )
  }

}

export default VacationQuotaFilter;
