import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon
} from 'mdbreact';



import DownloadActions from "./actions/DownloadActions";
import DownloadForm from "./DownloadForm";

import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';



class DownloadResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    this._onDelete = this._onDelete.bind(this);
    this._onDownload = this._onDownload.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onDelete(e) {
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      component.props.searchComponent.setState(prevState => ({
        loaded: false,
      }))

      DownloadActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.loadDownloadCollection();
        });
    }
  }

  _onDownload(e) {
    alert();
  }

  render() {

    //console.log(this.props.result);

    return (
      <tr className="download-item">
        <td>{ this.props.result.node.title[0].value }</td>
        <td>
          {this.props.result.node.body[0] &&
            <span className="hide" dangerouslySetInnerHTML={{__html: this.props.result.node.body[0].value}} />
          }

        </td>
        <td>
          <div className="action-bar">

            <a className="btn btn-info mr-2" color="info" href={this.props.result.field_file} target="" download={'download.' + this.props.result.extension}><MDBIcon icon="download" /></a>
              {PermissionHelper._hasPermission('restful delete Download') === true &&
                <>


                  <DownloadForm key={'download-edit-form-' + this.props.result.nid + this.props.result.changed} entity={this.props.result.node} searchComponent={this.props.searchComponent} category={this.props.result.category}  />

                  <MDBBtn color="danger" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>

                </>
              }
          </div>



        </td>
      </tr>
    );
  }
}

export default DownloadResultRow;
