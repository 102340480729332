import React, { Component } from 'react';
import Pager from 'react-pager';
import DownloadResultRow from "./DownloadResultRow";
import DownloadActions from "./actions/DownloadActions";
import Image from "../image/Image";
import DownloadFilter from "./DownloadFilter";
import DownloadForm from "./DownloadForm";
import PermissionHelper from "../../../helpers/PermissionHelper";

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../config';


class DownloadSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      downloadCategoriesFilter: [],
      downloadCategories: [],
      filters: {
        field_limit: 10,
        field_searchterm: '',
        field_download_category: null
      }
    }


    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadDownloadCollection();
    });
  }

  componentDidMount(){
    this.loadDownloadCollection();
    //console.log(this);
  }

  loadDownloadCollection() {


      var component = this;

      component.setState({
        collectionLoaded: false
      });

      /** build filter query **/

      DownloadActions._get('All', this.state.filters, this.state.page).then(response => {

        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }

        var downloadCategories = response.data.downloadCategories;
        var downloadCategoriesFilter = new Array();
        if(downloadCategories) {
          Object.entries(downloadCategories).forEach(function(item, key){
            var optionItem = {
              label: item[1].name[0].value,
              value: item[0]
            }
            downloadCategoriesFilter.push(optionItem);
            //console.log(item);
          });
        }

        //console.log(downloadCategoriesFilter);

        if(this.state.downloadCategoriesFilter.length == 0) {
          component.setState({
            downloadCategoriesFilter: downloadCategoriesFilter,
            downloadCategories: downloadCategories,
          });
        }

        component.setState({
          result: result,
          loaded: true,
          collectionLoaded: true,
          total: response.data.total
        });

      });
  }

  render() {
    let component = this;
    return (
      <>

      {PermissionHelper._hasPermission('restful post Download') === true && this.state.loaded &&
        <>
          <DownloadForm searchComponent={this} ref={this.formRef} nid={this.props.nid} />
          <hr />
        </>
      }



        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'download-loader'} red /></div>
        }

        {component.state.loaded && (
          <>

            <>
            <DownloadFilter searchComponent={this} />
            <hr />
            </>

            { component.state.result.length > 0 && (


              <MDBTable responsive hover striped className={`${!component.state.collectionLoaded ? "loading" : ""}`}>
                <thead>
                  <tr>
                    <th className='th-lg'>
                      Titel
                    </th>
                    <th className="hide">Beschreibung</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <DownloadResultRow result={row[1]}  key={'download-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>

            )}

            {component.state.result.length == 0 &&
              <div className="empty">Keine Downloads vorhanden</div>
            }

            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.filters.field_limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default DownloadSearch;
