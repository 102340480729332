import React, { Component } from 'react';

import moment from 'moment';
import 'moment/locale/de';

import {
  MDBTable,
  MDBBtn,
  MDBCollapseHeader,
  MDBIcon,
  MDBCardBody,
  MDBSpinner,
  MDBAlert,
  MDBFileInput
} from 'mdbreact';


import CanvasDraw from "react-canvas-draw";
import mergeImages from 'merge-images';
import SessionActions from './actions/SessionActions';



class SessionAgreement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      showSuccessMessage: false,
      file: false,
      field_image: false,
      canvasWidth: 0,
      canvasHeight: 0,
    }


    this.agreementRef = React.createRef();

    this.fileInputRef = React.createRef();


    this._onReset = this._onReset.bind(this);
    this._onAgreementClear = this._onAgreementClear.bind(this);
    this._onAgreementSave = this._onAgreementSave.bind(this);
    this._onAgreementUndo = this._onAgreementUndo.bind(this);
    this._onFileChangeHandler = this._onFileChangeHandler.bind(this);
  }


  componentDidMount() {

    var component = this;


  }


  _onAgreementClear(e) {
    e.preventDefault();
    this.agreementRef.current.clear();
  }

  _onAgreementUndo(e) {
    e.preventDefault();
    this.agreementRef.current.undo();
  }

  _onAgreementSave() {
    //e.preventDefault();
    var component = this;

    component.setState({
      isSaving: true,
      showSuccessMessage: false
    });

    var saveData = this.agreementRef.current.getSaveData();
    var drawImage = this.agreementRef.current.drawImage();
    console.log(saveData);
    var drawings = this.agreementRef.current.canvas.drawing.toDataURL();
    console.log(drawings);
    var agreementBackground = this.agreementRef.current.canvas.grid.toDataURL();
    console.log(agreementBackground);

    mergeImages([agreementBackground, drawings]).then(function(b64) {

      console.log(b64);


      var dataURI = b64;
      var BASE64_MARKER = ';base64,';
      var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
      var base64 = dataURI.substring(base64Index);
      var raw = window.atob(base64);
      var rawLength = raw.length;
      var array = new Uint8Array(new ArrayBuffer(rawLength));

      var i = 0;
      for(i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
      }


      SessionActions._uploadImage(array).then(response => {

        component.props.sessionForm.setState({
          field_image: response.data.fid[0].value,
        });


        component.setState({
          field_image: b64
        });


      });



    });

  }

  _onFileChangeHandler = files => {
    var component = this;

    component.setState({file: null, canvasWidth: 0, canvasHeight:0 }, function() {
      var FR= new FileReader();

      FR.addEventListener("load", function(e) {

        var image = new Image();
        image.src = e.target.result;

        image.onload = function() {
          // access image size here
          console.log(this.width);

          var imageLoaded = this;


          var ratio = this.width / this.height;


          if(this.width > 750) {

            component.setState({canvasWidth: 750, canvasHeight: 750 / ratio }, function() {
              console.log(component.state);
              console.log(this.agreementRef);

              setTimeout(function() {
                component.agreementRef.current.ctx.grid.fillStyle = "white";
                component.agreementRef.current.ctx.grid.fillRect(10, (750 / ratio) - 110, 730, 100);
            }, 100);


            });

          } else {

            component.setState({canvasWidth: this.width, canvasHeight: this.height }, function() {
              console.log(component.state);
              console.log(this.agreementRef);

              setTimeout(function() {
                component.agreementRef.current.ctx.grid.fillStyle = "white";
                component.agreementRef.current.ctx.grid.fillRect(10, imageLoaded.height - 110, 730, 100);
            }, 100);

            });

          }



        };

        component.setState({file: e.target.result } , () => console.log(component.state))

        //document.getElementById("img").src       = e.target.result;
        //document.getElementById("b64").innerHTML = e.target.result;
      });

      FR.readAsDataURL( files[0] );
    });
  }


  _onReset() {
    this.setState({
      field_image: false,
      isSaving: false,
      file: false
    });
  }

  render() {

    return(
      <>
      <div id="treatmentAgreementCanvas">






          {this.state.isSaving && !this.state.field_image &&
            <div className="text-center"><MDBSpinner key={'session-agreement-loader'} red /></div>
          }


          {!this.state.isSaving && !this.state.field_image &&
            <MDBFileInput textFieldTitle={''} btnTitle={'Datei auswählen'} getValue={this._onFileChangeHandler} />
          }



          {this.state.showSuccessMessage &&
            <MDBAlert color="success" dismiss>
              Die Vereinbarung wurde gespeichert.
            </MDBAlert>
          }

          {!this.state.isSaving && !this.state.field_image && this.state.file &&
            <div class="d-flex call-to-action">
              <MDBBtn color="danger" disabled={this.state.isSaving ? true : false}  className="btn-clear" onClick={this._onAgreementUndo}><MDBIcon icon="sync mr-2" /> Letzte Aktion widerufen</MDBBtn>
              <hr />
              <MDBBtn color="danger" disabled={this.state.isSaving ? true : false}  className="btn-clear" onClick={this._onAgreementClear}><MDBIcon icon="times mr-2" /> Unterschrift löschen</MDBBtn>
              <hr />
              <MDBBtn color="primary" disabled={this.state.isSaving ? true : false}  className="btn-save" onClick={this._onAgreementSave}><MDBIcon far icon="save mr-2" /> Unterschrift speichern</MDBBtn>
            </div>
          }


          {this.state.field_image &&
            <div className="aggreement-image-container">
              <img src={this.state.field_image} />
                <div class="d-flex call-to-action">
                  <MDBBtn color="primary" className="btn-reset" onClick={this._onReset}><MDBIcon icon="sync mr-2" /> Unterschrift löschen</MDBBtn>
                </div>
            </div>
          }



          <div className="treamtement-agreement-canvas">
            {!this.state.isSaving && this.state.file && !this.state.field_image && this.state.canvasWidth > 0 && this.state.canvasHeight > 0 &&
              <>
              <MDBAlert color="info" >
                Bitte bestätigen Sie die Behandlung mit einer Unterschrift.
              </MDBAlert>

                <CanvasDraw  id="treatmentAgreementCanvas" ref={this.agreementRef} immediateLoading={true} imgSrc={this.state.file} canvasWidth={this.state.canvasWidth} canvasHeight={this.state.canvasHeight} brushRadius={1} lazyRadius={0} brushColor={'#ff0000'} />
              </>

            }

          </div>
      </div>

      </>
    )
  }
}

export default SessionAgreement;
