import React, { Component } from 'react';
import {
  MDBInput,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBSelect,
  MDBBtn,
  MDBIcon
} from 'mdbreact';
import { Editor } from '@tinymce/tinymce-react';
import { history } from '../../../helpers/history';

import SessionActions from './actions/SessionActions';
import SessionParameter from "./SessionParameter";

import Select from 'react-select';

import moment from 'moment';
import 'moment/locale/de';


class SessionExtendedForm extends Component {

  constructor(props) {
    super(props);

    /** states **/
    this.state = {
      rows: [],
      sessions: [],
      zoneOptions: [],
      deviceOptions: [],
      sessionZones: [],
      saveSuccess: true,
      sessionCounter: 0
    }


    this.inputRef = React.createRef();

    this._onRowAdd = this._onRowAdd.bind(this);
    this._onRowDelete = this._onRowDelete.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onSelectChange = this._onSelectChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._duplicateLastSession = this._duplicateLastSession.bind(this);
  }


  shouldComponentUpdate(nextProps, nextState) {
    return true;
    if(this.state.sessions.length != nextState.sessions.length) {
      return true;
    } else {
      return false;
    }
    //return true;
  }

  componentDidMount() {



    if(this.props.zones) {
      var zoneOptions = this.state.zoneOptions;
      this.props.zones.map(function(item, n){
        var zoneOptionObj = {}
        zoneOptionObj.label = item.value;
        zoneOptionObj.value = item.value;
        zoneOptions.push(zoneOptionObj);
      });
      this.setState({zoneOptions: zoneOptions})
    }


    if(this.props.devices) {
      var deviceOptions = this.state.deviceOptions;
      this.props.devices.map(function(item, n){
        var deviceOptionObj = {}
        deviceOptionObj.label = item.value;
        deviceOptionObj.value = item.value;
        deviceOptions.push(deviceOptionObj);
      });
      this.setState({deviceOptions: deviceOptions})
    }

  }

  _onRowAdd(e) {
    e.preventDefault();

    var component = this;

    var rows = this.state.rows;
    rows.push('row_' + rows.length);
    //this.setState({rows: rows});

    this.setState(
      { rows: rows },
      () => console.log(this.state)
    );


    var sessions = this.state.sessions;
    var session = {
    }

    this.props.parameters.map(function(item, parameterIndex){
      var label = component.props.parameters[parameterIndex].value;
      session[label] = '';
    })

    session['body'] = '';

    sessions.push(session);
    var sessionCounter = this.state.sessionCounter + 1;
    //this.setState({sessions: sessions});
    this.setState(
      { sessions: sessions, sessionCounter: sessionCounter }, function() {
        //console.log(this.inputRef);
        if(this.inputRef.current) {
          //this.inputRef.current.focus();
        }
      }
    );
  }

  _onRowDelete(e, i) {
    var rows = this.state.rows;
    rows.splice(i, 1);
    //this.setState({rows: rows});

    this.setState(
      { rows: rows },
      () => console.log(this.state)
    );

    var sessions = this.state.sessions;
    sessions.splice(i, 1);


    var sessionCounter = this.state.sessionCounter - 1;

    this.setState({sessions: sessions, sessionCounter: sessionCounter});

    this.setState(
      { sessions: sessions, sessionCounter: sessionCounter },
      () => console.log(this.state)
    );
  }

  _onInputChange(e, parameterIndex, rowIndex, paramterName) {
    e.preventDefault();
    var name = e.target.name;
    var value = e.target.value;

    var sessions = this.state.sessions;
    var session = sessions[rowIndex];

    session[paramterName] = value;
    sessions[rowIndex] = session;
    this.setState({sessions: sessions});

    //console.log(this.state);
  }

  _onSelectChange(value, rowIndex, parameterIndex, paramterName) {
    let component = this;

    var sessions = this.state.sessions;
    var session = sessions[rowIndex];

    session[paramterName] = value;
    sessions[rowIndex] = session;

    this.setState({sessions: sessions});

    //console.log(this.state);
  }

  _onSubmit = event => {
    event.preventDefault();
    var component = this;
    var formErrors = false;

    component.setState({
      saveSuccess: false
    });

    this.state.sessions.map(function(session, sessionIndex){
      var values = {
        field_treatment: component.props.treatmentId,
        field_location: localStorage.getItem('selectedLocation'),
        parameterValues: Object.values(session),
        body: session['body']
      }
      SessionActions._post(values).then(response => {
        if(sessionIndex == component.state.sessions.length -1) {
          component.props.form._onToggleModal();
          component.setState({
            saveSuccess: true
          });

          component.props.form.props.searchComponent.loadSessionCollection();
          component.props.form.props.searchComponent.props.treatmentDetail._loadTreatmentDetails();
        }
      });
    })
  }

  _duplicateLastSession() {
    let component = this;

    var clickEvent = new MouseEvent("click",{bubbles: true, cancellable: true});

    //this._onRowAdd(clickEvent);

    var sessions = this.props.lastSessions[1];
    ///console.log(sessions);

    sessions.reverse().forEach(function (item, rowIndex) {
      component._onRowAdd(clickEvent);
      var parameters = JSON.parse(item.node.field_parameters[0].value);
      var body = '';

      if(item.node.body[0]) {
         body = item.node.body[0].value
      }


      var sessions = component.state.sessions;
      var session = sessions[rowIndex];


      //console.log(parameters);

      for (let key in parameters) {
        var parameter = parameters[key];
        var parameterKey = Object.keys(parameter);
        if(parameterKey == 'Zone') {
          component._onSelectChange(parameterKey, rowIndex, 'session_' + '0' + '_paramter' + rowIndex, 'Zone');
        }
        var parameterValue = Object.values(parameter);

        session[parameterKey[0]] = parameterValue[0];
      }



      session['body'] = body;




      sessions[rowIndex] = session;
      component.setState({sessions: sessions});



      //console.log(component.state.sessions);
    });
  }

  render() {
    let component = this;
    return (
      <section id="session-extended-form">
          <form
            onSubmit={this._onSubmit}
            >
          <MDBTable>
            <MDBTableHead>
              <tr>
                {this.props.parameters.map(function(item, parameterIndex){
                    //console.log(item);
                        return (
                          <th key={'session-paramter-head-' + parameterIndex}>{item.value}</th>
                        );
                  })}
                <th>Bemerkung</th>
                <th></th>
              </tr>
            </MDBTableHead>
            <MDBTableBody>

              {this.state.rows.map((row, rowIndex) => (
                <tr key={'session-row' + rowIndex + component.state.sessionCounter}>

                  {this.props.parameters.map(function(item, parameterIndex){
                      //console.log(item);
                      if(item.value == "Zone") {
                        var defaultValue = false;
                        if(component.state.sessions[rowIndex][component.props.parameters[parameterIndex].value]) {
                          var defaultValue = {
                            label: component.state.sessions[rowIndex][component.props.parameters[parameterIndex].value],
                            value: component.state.sessions[rowIndex][component.props.parameters[parameterIndex].value]
                          }
                        }

                        return (
                          <td key={'session-paramter-row-'+rowIndex+'-col-' + parameterIndex + component.state.sessionCounter} style={{"width" : "190px"}}>
                            <Select
                              isRequired={true}
                              key={'session_' + parameterIndex + '_paramter' + rowIndex}
                              className="react-select"
                              classNamePrefix="react-select"
                              placeholder=""
                              options={component.state.zoneOptions}
                              defaultValue={defaultValue}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(value) => {


                                //console.log(value);

                                if(value) {
                                  value = value.value;
                                } else {
                                  value = '';
                                }

                                component._onSelectChange(value, rowIndex, parameterIndex, item.value);
                              }}
                            />

                          </td>
                        )


                      }
                      else if(item.value == "Gerät") {
                        var defaultValue = false;
                        if(component.state.sessions[rowIndex][component.props.parameters[parameterIndex].value]) {
                          var defaultValue = {
                            label: component.state.sessions[rowIndex][component.props.parameters[parameterIndex].value],
                            value: component.state.sessions[rowIndex][component.props.parameters[parameterIndex].value]
                          }
                        }

                        return (
                          <td key={'session-paramter-row-'+rowIndex+'-col-' + parameterIndex + component.state.sessionCounter} style={{"width" : "190px"}}>
                            <Select
                              isRequired={true}
                              key={'session_' + parameterIndex + '_paramter' + rowIndex}
                              className="react-select"
                              classNamePrefix="react-select"
                              placeholder=""
                              options={component.state.deviceOptions}
                              defaultValue={defaultValue}
                              isClearable={true}
                              isSearchable={true}
                              onChange={(value) => {


                                //console.log(value);

                                if(value) {
                                  value = value.value;
                                } else {
                                  value = '';
                                }

                                component._onSelectChange(value, rowIndex, parameterIndex, item.value);
                              }}
                            />

                          </td>
                        )


                      }
                      else {
                        return (
                          <td key={'session-paramter-col-' + parameterIndex}>
                            <MDBInput required ref={component.inputRef} key={'session_' + parameterIndex + '_paramter' + rowIndex} value={component.state.sessions[rowIndex][component.props.parameters[parameterIndex].value]} onChange={(event) => component._onInputChange(event, parameterIndex, rowIndex, component.props.parameters[parameterIndex].value)} background size="sm" name={'session_' + parameterIndex + '_paramter'} />
                          </td>
                        );
                      }

                    })}
                  <td><MDBInput key={'session_' + component.props.parameters.length + '_paramter' + rowIndex} value={component.state.sessions[rowIndex]['body']} background size="sm" name={'session_' + component.props.parameters.length + '_paramter'} onChange={(event) => component._onInputChange(event, component.props.parameters.length, rowIndex, 'body')} /></td>
                  <td>
                    <MDBBtn color="red" className="mr-2" onClick={(event) => this._onRowDelete(event, rowIndex)}>
                      <MDBIcon far icon="trash-alt" />
                    </MDBBtn>
                  </td>
                </tr>
                    ))}


            </MDBTableBody>
          </MDBTable>

          <hr />

        <MDBBtn color="primary" className="mr-2 btn-add" onClick={this._onRowAdd}>
          <MDBIcon icon="plus-circle" /> Zeile hinzufügen
        </MDBBtn>

        {this.state.rows.length == 0 && this.props.lastSessions &&
          <MDBBtn color="primary" className="mr-2" onClick={this._duplicateLastSession}>
            <MDBIcon icon="layer-group mr-2" /> Letzte Sitzung kopieren
          </MDBBtn>
        }


        {this.state.rows.length > 0 && this.state.saveSuccess &&
          <MDBBtn color="primary" type="submit"><MDBIcon far icon="save mr-2" /> Sitzung abschliesen</MDBBtn>
        }

        {!this.state.saveSuccess &&
          <MDBBtn color="primary" type="submit" disabled><MDBIcon far icon="save mr-2" /> Sitzung abschliesen</MDBBtn>
        }

      </form>

      </section>
    )
  }
}
export default SessionExtendedForm;
