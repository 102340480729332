import React, { Component } from 'react';
import Pager from 'react-pager';
import FaqResultRow from "./FaqResultRow";
import FaqActions from "./actions/FaqActions";
import Image from "../image/Image";
import FaqFilter from "./FaqFilter";
import FaqForm from "./FaqForm";
import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabPane,
  MDBTabContent,
  MDBCollapseHeader,
  MDBCollapse
} from 'mdbreact';

import PermissionHelper from "../../../helpers/PermissionHelper";

import config from '../../../config';


class FaqSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      activeItem: 0,
      collapseID: 0,
      loaded: false,
      collectionLoaded: false,
      result: [],
      page: 0,
      limit: 10000,
      total: 0,
      visiblePage: 5,
      faqCategoriesFilter: [],
      categoryGroup: [],
      filters: {
        field_searchterm: '',
        field_faq_category: null
      }
    }


    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    this._onToggle = this._onToggle.bind(this);
    this._onToggleCollapse = this._onToggleCollapse.bind(this);
    //console.log(props);
  }

  _onToggle = tab => e => {
      if (this.state.activeItem !== tab) {
        this.setState({
          activeItem: tab
        });
      }
    };


    _onToggleCollapse(collapseID) {
      this.setState(prevState => ({
        collapseID: prevState.collapseID !== collapseID ? collapseID : ""
      }));

    }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadFaqCollection();
    });
  }

  _onDelete(nid) {
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      FaqActions._delete(nid)
        .then(response => {
          component.formRef.current._onFormReset();
          component.loadFaqCollection();
        });
    }
  }

  componentDidMount(){
    this.loadFaqCollection();
    //console.log(this);
  }

  loadFaqCollection() {


      var component = this;

      /** build filter query **/

      component.setState({
        collectionLoaded: false
      });

      FaqActions._get('All', this.state.filters, this.state.page).then(response => {
        //  console.log(response.data.entity);
        //console.log(response.data.result);


        var faqCategories = response.data.faqCategories;
        var faqCategoriesFilter = new Array();
        if(faqCategories) {
          Object.entries(faqCategories).forEach(function(item, key){
            var optionItem = {
              label: item[1].name[0].value,
              value: item[0]
            }
            faqCategoriesFilter.push(optionItem);
            //console.log(item);
          });
        }

        if(this.state.faqCategoriesFilter.length == 0) {
          component.setState({
            faqCategoriesFilter: faqCategoriesFilter,
            faqCategories: faqCategories,
          });
        }







        //console.log(faqCategoriesFilter);

        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }

        var categoryGroup = [];
        if(response.data.categoryGroup) {
          categoryGroup = response.data.categoryGroup;
        }




        component.setState({
          result: result,
          categoryGroup: categoryGroup,
          loaded: true,
          collectionLoaded: true,
          total: response.data.total
        });

      });
  }

  render() {
    let component = this;

    //console.log(typeof(component.state.faqCategories));

    return (
      <>





        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'faq-loader'} red /></div>
        }

        {component.state.loaded && (
          <>
          {PermissionHelper._hasPermission('restful post Faq') === true &&
            <>
              <FaqForm searchComponent={this} ref={this.formRef} />
            </>
          }


        <FaqFilter searchComponent={this} />
        <hr />

            { component.state.result.length > 0 && (

              <div className="faq-tabs">


              <MDBNav className="md-tabs">
                {true && (
                  Object.values(component.state.faqCategories).map(function(row, j){
                    //console.log(j)
                    if(component.state.categoryGroup[row.tid[0].value]) {
                      return (
                        <MDBNavItem key={'faq-nav-' + j}>
                          <MDBNavLink link to="#" active={component.state.activeItem === j} onClick={component._onToggle(j)}  role="tab" >
                            {row.name[0].value}
                          </MDBNavLink>
                        </MDBNavItem>
                      )
                    }
                  })
                )}
              </MDBNav>
              <MDBTabContent activeItem={this.state.activeItem} >
                {true && (
                  Object.values(component.state.faqCategories).map(function(categoryRow, j){
                    //console.log(component.state.categoryGroup);

                    if(component.state.categoryGroup[categoryRow.tid[0].value]) {
                      return (
                        <MDBTabPane tabId={j} role="tabpanel" key={'faq-tab-' + j}>
                          {true && (
                            Object.values(component.state.categoryGroup[categoryRow.tid[0].value]).map(function(row, i){
                              //console.log(row)
                              return (

                                <MDBCard className='mt-3' key={'faq-card-' + i}>
              <MDBCollapseHeader
                tagClassName=''


                onClick={() => { component._onToggleCollapse(row.nid) }}
              >
                <div>
                  {row.node.title[0].value}



                </div>
                <MDBIcon
                  icon={component.state.collapseID === row.nid ? 'angle-up' : 'angle-down'}
                />


              </MDBCollapseHeader>
              <div className="action-bar">
                {PermissionHelper._hasPermission('edit any faq content') === true &&
                  <>
                    <MDBBtn color="danger" onClick={() => { component._onDelete(row.node.nid[0].value) }}><MDBIcon far icon="trash-alt" /></MDBBtn>
                    <FaqForm key={'faq-edit-form-' + row.nid + row.changed} entity={row.node} searchComponent={component} locations={row.locations} category={row.category}  />
                  </>
                }
              </div>
              <MDBCollapse id={row.nid} isOpen={component.state.collapseID == row.nid}>
                <MDBCardBody>
                  <FaqResultRow result={row}  key={'faq-result-view-' + row.nid + row.changed} searchComponent={component} />
                </MDBCardBody>
              </MDBCollapse>
            </MDBCard>


                              )
                            })
                          )}
                        </MDBTabPane>
                      )
                    }


                  })
                )}
              </MDBTabContent>
            </div>
            )}

            {component.state.result.length == 0 &&
              <div className="empty">Keine Faq vorhanden</div>
            }


          </>
        )}
      </>
    );
  }
}

export default FaqSearch;
