import React, { Component } from 'react';
import {
  MDBBtn,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBInput,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBSelect,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBAnimation,
  MDBNavLink,
  MDBIcon,
  MDBSpinner,
  MDBAlert,
  MDBCollapseHeader,
  MDBCollapse
} from 'mdbreact';

import Select from 'react-select'

import TreatmentActions from './actions/TreatmentActions';

import TreatmentFormAnamnesisItem from './TreatmentFormAnamnesisItem';

import { history } from '../../../helpers/history';

import SignaturePad from 'react-signature-canvas';


class TreatmentForm extends Component {

  constructor(props) {
    super(props);
    /** states **/
    this.state = {
      hasSubmitted: false,
      collapseID: "collapse2",
      entity: null,
      typeOptions: [],
      imageURL: null,
      image: null,
      modal: false,
      showAgreement: false,
      showAnamnesis: false,
      showSignPad: false,
      fields: {
        field_location: localStorage.getItem('selectedLocation'),
        field_customer: this.props.nid,
        field_treatment_type: null,
        field_channel: null,
        field_channel_other: null,
        field_image: null
      }
    }


    /** refs **/
    this.sigCanvas = React.createRef();
    this.modalRef = React.createRef();

    /** binding methods **/
    this._onSubmit = this._onSubmit.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
    this._onToggleCollapse = this._onToggleCollapse.bind(this);
    this._onCheckboxChange = this._onCheckboxChange.bind(this);
    this._onSignatureSave = this._onSignatureSave.bind(this);
    this._onSignatureClear = this._onSignatureClear.bind(this);
    this._onTypeChange = this._onTypeChange.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onChannelChange = this._onChannelChange.bind(this);
    this._onPreventToggle = this._onPreventToggle.bind(this);
  }

  componentDidMount() {
    var component = this;



    component.setState({
      typeOptions: this.props.searchComponent.state.treatmentTypesNew
    });

    component.setState({
      channelOptions: this.props.searchComponent.state.channelOptions
    });
    component.state.typeOptions.forEach(function(item, index) {
      if(item.value == component.state.fields.field_treatment_type) {
        item.checked = true;
      }
    });



  }

  _onToggleCollapse = collapseID => () => {
    //console.log(this.modalRef.current.children);
    //console.log(this.modalRef.current.children.length);
    //console.log(this.modalRef.current.children['2']);
    //console.log(this.modalRef.current.children.index(3));
    this.modalRef.current.children['2'].scrollTo(0, 0);
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }

  _onToggleCollapse_(collapseID) {
    window.scrollTo(0, 0);
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
  }

  _onToggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onSubmit(event) {
    event.preventDefault();
    let component = this;


    this.setState(prevState => ({
      hasSubmitted: true,
    }))

    TreatmentActions._post(this.state.fields).then(response => {
      component._onToggleModal();
      component.props.searchComponent.loadTreatmentCollection();

      component.setState(prevState => ({
        hasSubmitted: false,
      }))

    });
  }

  _onInputChange(e) {

    //console.log(e);
    var fields = this.state.fields;
    //console.log(fields);
    var name = e.target.name;
    fields[name] = e.target.value;
    this.setState({
      fields: fields
    });
  }

  _onTypeChange(e) {
    let component = this;


    var fields = {
      field_location: localStorage.getItem('selectedLocation'),
      field_customer: this.props.nid,
      field_channel: this.state.fields.field_channel,
      field_channel_other: this.state.fields.field_channel_other,
      imageURL: null,
    }

    fields.field_treatment_type = e.target.value;

    component.setState({
      fields: fields,
      imageURL: null
    });


    this.state.typeOptions.map(function(item, n){

      if(item.value === e.target.value) {
        //console.log(item.agreementDetails.length);

        if(item.agreementDetails.length > 0) {
          component.setState({
            showAgreement: true
          });
        } else {
          component.setState({
            showAgreement: false
          });
        }

        if(item.anamnesis.length > 0) {
          component.setState({
            showAnamnesis: true
          });
        } else {
          component.setState({
            showAnamnesis: false
          });
        }
      }
    })


  };

  _onChannelChange(value) {

    var fields = this.state.fields;
    fields.field_channel = value;

    this.setState({
      fields: fields
    });
  };

  _onCheckboxChange(e) {
    //console.log(e.target.checked);
    this.setState({
      [e.target.name]: e.target.checked
    },() => {
        //console.log(this.state);
    });
  }

  _onSignatureSave() {



    if(this.state.hasSubmitted) {
      return;
    }

    this.setState(prevState => ({
      hasSubmitted: true,
    }))


    var component = this;
    this.setState({
      imageURL: this.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
    });

    var dataURI = this.sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    var BASE64_MARKER = ';base64,';
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    var i = 0;
    for(i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }

    TreatmentActions._uploadImage(array).then(response => {

      component.sigCanvas.current.clear();

      var fields = component.state.fields;
      fields.field_image = response.data.fid[0].value;



      component.setState({
        fields: fields
      }, function(e) {
          var event = new Event('submit');
          component._onSubmit(event);
      });
    });

  }

  _onSignatureClear() {
    if(this.sigCanvas.current) {
      this.sigCanvas.current.clear();
    }

    var fields = this.state.fields;
    fields.field_image = false;
    this.setState({
      imageURL: null,
      fields: fields
    });
  }

  _onPreventToggle() {
    return false;
  }


  render() {
    let component = this;
    return (
      <>
      <section id="customer-treatment-form" ref={this.modalRef}>
        <MDBBtn color="primary" onClick={this._onToggleModal}><MDBIcon icon="plus mr-2" /> Neue Behandlung starten</MDBBtn>
        <MDBModal isOpen={this.state.modal} toggle={this._onPreventToggle} size="lg">
          <MDBModalHeader toggle={this._onToggleModal}>Neue Behandlung</MDBModalHeader>
          <MDBModalBody>
            <form id="treatment-form" onSubmit={this._onSubmit}>
              <MDBRow>
                <MDBCol className="text-left">


                  <Select
                    className="react-select"
                    classNamePrefix="react-select"
                    placeholder="Behandlung wählen"
                    options={this.state.typeOptions}
                    isClearable={true}
                    onChange={(value) => {

                      if(value) {
                        value = value.value;
                      } else {
                        value = '';
                      }

                      this._onTypeChange({
                        target: {
                          name: 'field_treatments',
                          value: value,
                        },
                      });
                    }}
                  />




                </MDBCol>
              </MDBRow>

              {component.state.fields.field_treatment_type && false &&
                <MDBRow>

                  <MDBCol className="text-left">
                    <MDBSelect
                      selected="Bitte auswählen"
                      name="field_channel"
                      options={this.state.channelOptions}
                      error='wrong'
                      success='right'
                      required
                      getValue={this._onChannelChange}
                      onChange={this._onChannelChange}
                      label="Channel"
                    />
                  </MDBCol>
                </MDBRow>
              }


              {component.state.fields.field_channel == '29' && false &&
                <MDBRow>
                  <MDBCol className="text-left">
                    <MDBInput outline id="field_channel_other" name="field_channel_other" value={component.state.fields.field_channel_other} onChange={component._onInputChange} label="Empfohlen von" required />
                  </MDBCol>
                </MDBRow>
              }

              {component.state.fields.field_channel == '32' && false &&
                <MDBRow>
                  <MDBCol className="text-left">
                    <MDBInput outline id="field_channel_other" name="field_channel_other" value={component.state.fields.field_channel_other} onChange={component._onInputChange} label="Andere" required />
                  </MDBCol>
                </MDBRow>
              }

              {component.state.fields.field_treatment_type &&
                <>
                <hr />
                  {component.state.showAnamnesis &&
                    <>

                    <MDBCollapseHeader onClick={this._onToggleCollapse("collapse2")}>
                      Fragebogen
                      <i className={ this.state.collapseID==="collapse2" ? "fa fa-angle-down" : "fa fa-angle-up" } />
                    </MDBCollapseHeader>
                    <MDBCollapse id="collapse2" isOpen={this.state.collapseID}>
                      <MDBCardBody>
                        <div className="anamnesis-bar">
                          {this.state.typeOptions.map(function(item, n){
                            if(item.value === component.state.fields.field_treatment_type) {
                              return item.anamnesis.map(function(question, m){
                                let fieldName = 'anamnesis_' + m;
                                let prev = m - 1;
                                let prevFieldName = 'anamnesis_' + prev;
                                let next = m + 1;
                                let nextFieldName = 'anamnesis_' + next;
                                let fieldNameCheck = 'anamnesis_check_' + m;
                                return (
                                  <TreatmentFormAnamnesisItem key={m} question={question} index={m} fieldName={fieldName} fieldNameCheck={fieldNameCheck} form={component} />
                                );
                              })
                            } else {
                              return null;
                            }
                          })}
                        </div>
                      </MDBCardBody>
                    </MDBCollapse>
                    </>
                  }

                  {component.state.showAgreement &&
                    <>
                    <MDBCollapseHeader onClick={this._onToggleCollapse("collapse1")}>
                      Vereinbarung
                      <i className={ this.state.collapseID==="collapse1" ? "fa fa-angle-down" : "fa fa-angle-up" } />
                    </MDBCollapseHeader>
                    <MDBCollapse id="collapse1" isOpen={this.state.collapseID}>
                      <MDBCardBody>
                        <div className="agreement-bar">
                          {this.state.typeOptions.map(function(item, i){
                            if(item.value === component.state.fields.field_treatment_type) {
                              return item.agreement.map(function(question, j){
                                let fieldName = 'agreement_' + j;
                                let prev = j - 1;
                                let prevFieldName = 'agreement_' + prev;
                                let next = j + 1;
                                let nextFieldName = 'agreement_' + next;
                                return (
                                  <div className={`form-item`} key={'agreement-form-item-' + j}>
                                    <MDBInput
                                      name={fieldName}
                                      label={question.value}
                                      group
                                      type='text'
                                      error='wrong'
                                      success='right'
                                      required
                                      onChange={component._onInputChange}
                                      value={component.state.fields.fieldName}
                                      key={fieldName}
                                    />
                                  </div>
                                );
                              })
                            } else {
                              return null;
                            }
                          })}
                        </div>
                        <div className="agreement-details-bar">
                          {this.state.typeOptions.map(function(item, k){
                            if(item.value === component.state.fields.field_treatment_type) {
                              return item.agreementDetails.map(function(details, l){
                                let fieldName = 'agreement-details_' + l;
                                let prev = l - 1;
                                let prevFieldName = 'agreement-details' + prev;
                                let next = l + 1;
                                let nextFieldName = 'agreement-details' + next;
                                return (
                                  <div className="details-bar" key={l}>
                                    <div dangerouslySetInnerHTML={{__html: details.value}} />
                                    <hr />
                                  </div>
                                );
                              })
                            } else {
                              return null;
                            }
                          })}
                        </div>
                      </MDBCardBody>
                    </MDBCollapse>
                    </>
                  }

                  {component.state.showAgreement &&
                    <>
                      <hr />

                      <div className="advise-bar">
                        <MDBAlert color="warning" >
                          DIe Vereinbarung und die Fragen habe ich gelesen und verstanden. Ich bin volljährig und geschäftsfähig bzw. habe diese Erklärung einer erziehungsberechtigten Person zu Unterschrift vorgelegt. Ich hatte ausreichend Zeit und Gelegenheit, meine Entscheidung zu überdenken und habe keine weiteren Fragen mehr, nachdem die von mir gestellten, vollständig und auch für den Laien verständlich, beantwortet wurden. Mir ist bewusst, dass eine Erfolgsgarantie nicht mit letztlicher Sicherheit gegeben werden kann. Dennoch erkläre ich mich mit der Durchführung der oben näher bezeichneten Behandlung einverstanden.
                        </MDBAlert>

                        {!component.state.showSignPad &&
                          <MDBBtn
                            color="primary"
                            onClick={() => component.setState({showSignPad: true})}
                            >
                            <MDBIcon icon="signature mr-2" /> Unterschreiben
                          </MDBBtn>
                        }

                      </div>


                      {!component.state.fields.field_image && component.state.showSignPad &&
                          <>
                            <h4>Ihre Unterschrift</h4>
                            <SignaturePad
                              ref={component.sigCanvas}
                              canvasProps={{
                                className: "signatureCanvas"
                              }}
                            />
                            <hr />
                            {/* Button to trigger save canvas image */}
                            <MDBBtn onClick={component._onSignatureClear} color="primary" className="mr-2"><MDBIcon icon="sync" className="mr-2" /> Unterschrift löschen</MDBBtn>
                            <MDBBtn onClick={component._onSignatureSave} color="primary"><MDBIcon far icon="save mr-2" /> Unterschrift speichern</MDBBtn>
                          </>
                      }

                      {component.state.fields.field_image ? (
                        <>
                          <h4>Ihre Unterschrift</h4>
                          <img
                            id="sigCanvasImage"
                            src={component.state.imageURL}
                            alt="my signature"
                            style={{
                              display: "block",
                              width: "600px"
                            }}
                          />
                        <hr />
                        <MDBBtn onClick={component._onSignatureClear} color="primary" className="mr-2"><MDBIcon icon="sync" className="mr-2" /> Unterschrift löschen</MDBBtn>


                          {component.state.fields.field_image && component.state.showAgreement &&
                              <MDBBtn color="primary" type="submit" disabled={((this.state.hasSubmitted)) ? true : false}><MDBIcon far icon="save mr-2" /> Behandlung speichern</MDBBtn>
                          }

                          {!component.state.showAgreement &&
                              <MDBBtn color="primary" type="submit" disabled={((this.state.hasSubmitted)) ? true : false}><MDBIcon far icon="save mr-2" /> Behandlung speichern</MDBBtn>
                          }

                        </>
                      ) : null}

                    </>
                  }



                </>
              }

            </form>
          </MDBModalBody>
        </MDBModal>
      </section>
      </>
    );
  }
}

export default TreatmentForm;
