import React, {
  useState,
  useEffect
} from 'react';

import {
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBSpinner,
  MDBPopover,
  MDBPopoverBody,
  MDBPopoverHeader,
  MDBInput,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBDropdownMenu,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBModalFooter,
  MDBAvatar
} from 'mdbreact';

import Image from "../image/Image";

import NumberFormat from 'react-number-format';


import VacationQuotaActions from "./actions/VacationQuotaActions";
import VacationQuotaForm from "./VacationQuotaForm";

import moment from 'moment';
import 'moment/locale/de';

import PermissionHelper from "../../../helpers/PermissionHelper";



class VacationQuotaResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modal: false
    };

    this._onDelete = this._onDelete.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onDelete(e) {
    var component = this;

    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      VacationQuotaActions._delete(this.props.result.uid)
        .then(response => {
          component.props.searchComponent.formRef.current._onFormReset();
          component.props.searchComponent.loadVacationQuotaCollection();
        });
    }
  }

  render() {
    console.log(this.props);
    return (
      <tr className="vacationquota-item" key={'vacationquota-' + this.props.result.uid + this.props.result.changed}>

        <td>{(this.props.result.field_vacation_quota_number[0] ? this.props.result.field_vacation_quota_number[0].value : '')}</td>
        <td>{(this.props.result.field_employee[0] ? this.props.result.field_employee[0].target_id : '')}</td>
        <td>{(this.props.result.field_year[0] ? this.props.result.field_year[0].value : '')}</td>
        <td>{(this.props.result.field_quota[0] ? this.props.result.field_quota[0].value : '')}</td>
        <td></td>
      </tr>
    );
  }
}

export default VacationQuotaResultRow;
