import React, {
  useState,
  useEffect
} from 'react';
import {
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBInput,
  MDBSpinner,
  MDBDatePicker,
  MDBTimePicker,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBNavLink,
  MDBDropdownItem,
  MDBModal,
  MDBModalBody,
  MDBRow,
  MDBCol,
  MDBModalFooter,
  MDBBadge
} from 'mdbreact';
import TaskActions from "./actions/TaskActions";

import TaskComment from "../comment/CommentModalSearch.jsx";

import PermissionHelper from "../../../helpers/PermissionHelper";



import moment from 'moment';
import 'moment/locale/de';


class TaskResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editLoaded: true,
      loaded: true,
      deleteLoaded: true,
      field_status: props.result.field_status,
      body: props.result.body,
      field_date: new Date(props.result.field_date),
      field_time: this._convertTime(parseInt(props.result.field_time)),
      nid: props.result.nid,
      countImportant:0
    };


    this._onToggleEditMode = this._onToggleEditMode.bind(this);
    this._onComplete = this._onComplete.bind(this);
    this._onDelete = this._onDelete.bind(this);
    this._onSave = this._onSave.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onGetDatePickerValue = this._onGetDatePickerValue.bind(this);
    this._onGetTimePickerValue = this._onGetTimePickerValue.bind(this);
    this._convertTime = this._convertTime.bind(this);
  }


  _onGetDatePickerValue = (value) => {
    //console.log(value);
    this.setState(prevState => ({
      'field_date': value,
    }))
  }

  _onGetTimePickerValue(value) {
    //console.log(value);
    this.setState(prevState => ({
      'field_time': value,
    }))
  }

  _onToggleEditMode(e) {
    this.setState({
      editMode: !this.state.editMode
    });

    //console.log(this.state);
  }

  _onComplete(e) {
    var component = this;
    this.setState({
      loaded: true,
      field_status: component.state.field_status == 0 ? 1 : 0
    });


    let values = {
      field_date: this.state.field_date,
      field_time: this.state.field_time,
      body: this.state.body,
      [e.target.name]: e.target.value,
      nid: this.state.nid
    }

    //console.log(values);
    TaskActions._patch(values)
      .then(response => {
        component.props.listComponent.loadTaskCollection();
        component.setState({
          loaded: true
        });
      });
  }

  _onDelete(e) {
    var component = this;



    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      this.setState({
        deleteLoaded: false
      });
      TaskActions._delete(this.state.nid)
        .then(response => {
          component.props.listComponent.loadTaskCollection();
        });
    }
  }

  _onSave(e) {
    var component = this;


    this.setState({
      editLoaded: false
    });


    let values = {
      field_date: this.state.field_date,
      field_time: this.state.field_time,
      body: this.state.body,
      field_status: this.state.field_status,
      nid: this.state.nid
    }

    //console.log(values);
    TaskActions._patch(values)
      .then(response => {
        component.props.listComponent.loadTaskCollection();
        component.setState({
          editMode: !component.state.editMode,
          editLoaded: true
        });
      });
  }

  _onInputChange(e) {
    console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _convertTime(milliseconds) {
    const formatted = moment.utc(milliseconds * 1000)
      .format('HH:mm');
    return formatted;
  }

  render() {



    var date = new Date(this.props.result.field_date);
    var time = this._convertTime(parseInt(this.props.result.field_time));
    var timeParts = time.split(':');

    var locationTaskPermission = true;


    if(!PermissionHelper._hasPermission('config location task') && this.props.route == '/taskteam') {
      locationTaskPermission = false;
    }


    return (
      <tr>
        <td>


          {!this.state.editMode &&
            <span className="sui-result__value">{timeParts[0]}:{timeParts[1]}</span>
          }

          {this.state.editMode &&
            <>

            <div className="md-form form-group md-outline">
              <MDBInput outline type="date" id="field_date" label="Datum" name="field_date" value={moment(this.state.field_date).format('YYYY-MM-DD')} onChange={this._onInputChange} required />
            </div>

            <div className="md-form form-group md-outline">
              <MDBInput outline type="time" id="field_time" label="Zeit" name="field_time" value={this.state.field_time} onChange={this._onInputChange} required />
            </div>

            </>
          }
        </td>
        <td>
          {!this.state.editMode &&
            <span className="sui-result__value">{this.props.result.body}</span>
          }
          {this.state.editMode &&
            <MDBInput outline type="textarea" label="Bemerkung" rows="3" onChange={this._onInputChange} name="body" value={this.state.body} />
          }
        </td>

        <td>
          {!this.state.loaded &&
            <MDBSpinner key={'complete-loader-' + this.state.nid} small red />
          }


          {this.state.loaded && locationTaskPermission &&
            <MDBInput
              key={'task-status-' + this.state.nid}
              name="field_status"
              label="Aufgabe abschliesen"
              type="checkbox"
              checked={Boolean(parseInt(this.state.field_status))}
              id={'task-status-' + this.state.nid}
              onChange={(e) => {
                this._onComplete({
                  target: {
                    name: e.target.name,
                    value: e.target.checked,
                    dataset: e.target.dataset
                  },
                });
              }}
            />
          }


        </td>
        <td className="text-right">

          {!this.state.editMode &&
            <>
              {!this.state.deleteLoaded &&
                <MDBSpinner key={'delete-loader-' + this.state.nid} small />
              }

              {this.state.deleteLoaded &&
                <>

                <div className="action-bar">

                    {locationTaskPermission &&
                      <MDBBtn color="primary" onClick={this._onToggleEditMode}><MDBIcon far icon="edit" /></MDBBtn>
                    }



                    <TaskComment component={this} entityId={this.props.result.nid} entityType="node" fieldName="field_task_comment" commentType="task_comment" showImportant={true} />


                  {/*<MDBBtn color="danger" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>*/}
                </div>


                </>
              }
            </>
          }

          {this.state.editMode &&
            <>
              {!this.state.editLoaded &&
                <MDBSpinner key={'edit-loader-' + this.state.nid} red />
              }

              {this.state.editLoaded &&
                <>
                  <div className="action-bar">
                    <MDBBtn color="default" onClick={this._onSave}>
                      <MDBIcon far icon="save" />
                    </MDBBtn>
                    <MDBBtn color="danger" onClick={this._onToggleEditMode}>
                      <MDBIcon icon="ban" />
                    </MDBBtn>



                  </div>
                </>
              }
            </>
          }






        </td>
      </tr>

    );
  }
}

export default TaskResultRow;
