import React, {Component} from "react";
import {DebounceInput} from 'react-debounce-input';

import LimitSelect from "../misc/LimitSelect";


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBBtn
} from 'mdbreact';

import Select from 'react-select'


class DownloadFilter extends Component {

  constructor(props) {
    super(props);
    this._onSelectChange = this._onSelectChange.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onCheckboxChange = this._onCheckboxChange.bind(this);
    this._onDirectionChange = this._onDirectionChange.bind(this);
    this._updateSearchFilter = this._updateSearchFilter.bind(this);
  }

  componentDidMount() {

  }

  _onCheckboxChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value) {
      value = e.target.value;
    } else {
      value = null;
    }
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _onDirectionChange(e) {
    var name = e.target.name;
    var value = e.target.value;

    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;

    this._updateSearchFilter(filters);
  }

  _onInputChange(e) {
    //console.log(e);
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _updateSearchFilter(filters) {

    var component = this;

    //console.log(filters);
    component.props.searchComponent.setState({
        filters: filters,
        collectionLoaded: false,
        page: 0
    }, () => {
        var downloadFilter = component.props.searchComponent.state;
        localStorage.setItem('downloadFilter', JSON.stringify(downloadFilter));
        component.props.searchComponent.loadDownloadCollection();
    });
  }

  render() {
    let component = this;
    return (
      <div className="filter-card">
        <MDBRow>
          <MDBCol size="2" className="d-flex col-sort">

            <Select
              className="react-select"
              classNamePrefix="react-select"
              placeholder="Kategorie"
              options={this.props.searchComponent.state.downloadCategoriesFilter}
              defaultValue={(this.props.searchComponent.state.filters.field_download_category) ? { label: this.props.searchComponent.state.downloadCategoriesFilter.find(x => x.value === this.props.searchComponent.state.filters.field_download_category.toString()).label, value: this.props.searchComponent.state.filters.field_download_category.toString() } : false}
              isClearable={true}
              onChange={(value) => {

                if(value) {
                  value = value.value;
                } else {
                  value = '';
                }

                this._onSelectChange({
                  target: {
                    name: 'field_download_category',
                    value: value,
                  },
                });
              }}
            />

        <LimitSelect onSelectChange={this._onSelectChange} />



          </MDBCol>
        </MDBRow>
        <hr />
          <MDBRow>
            <MDBCol>
                <div className="sui-search-box">
                  <div className="sui-search-box__wrapper">
                    <DebounceInput
                      minLength={3}
                      debounceTimeout={800}
                      id="field_searchterm"
                      name="field_searchterm"
                      placeholder="Suchbegriff"
                      className="sui-search-box__text-input "
                      onChange={(e) => {
                        this._onInputChange({
                          target: {
                            name: 'field_searchterm',
                            value: e.target.value,
                          },
                        });
                      }}
                      value={this.props.searchComponent.state.filters.field_searchterm}
                    />

                   {!this.props.searchComponent.state.collectionLoaded && (
                     <><MDBSpinner red small /></>
                   )}
                  </div>
                </div>
            </MDBCol>
          </MDBRow>
      </div>
    )
  }

}

export default DownloadFilter;
