
import { userConstants } from '../constants/userConstants';
import userService from '../services/userService';
import { history } from '../helpers/history';
import { Component } from 'react';


class userActions extends Component {
  constructor(props) {
    super(props);
    /** binding methods **/
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }


  static login(username, password) {

    //console.log('userAction login called');
    return dispatch => {

      dispatch(userActions.request());

      return userService.login(username, password)
        .then(
            response => {
                //console.log(response);

                return dispatch(userActions.success(response));
                history.push('/dashboard');
            },
            error => {
                //console.log(error);
                return dispatch(userActions.failure(error));
            }
        );
    };
  }


  static loginByCard(token) {

    //console.log('userAction login called');
    return dispatch => {

      dispatch(userActions.request());

      return userService.loginByCard(token)
        .then(
            response => {
                //console.log(response);

                return dispatch(userActions.success(response));
                history.push('/dashboard');
            },
            error => {
                //console.log(error);
                return dispatch(userActions.failure(error));
            }
        );
    };
  }

  static logout() {
    //console.log('userActions: logout');
    return dispatch => {
      dispatch(userActions.request());
       userService.logout().then(
          response => {
               dispatch(userActions.failure(response));
          },
          error => {
               dispatch(userActions.failure(error));
          }
      );


    }
  }
  static checkSession(user) {
    //console.log(user);
    //console.log('userAction checkSession called');
    return dispatch => {
      userService.checkSession(user)
          .then(
              response => {
                return dispatch(userActions.success(response));
              },
              error => {
                userActions.logout();
                return dispatch(userActions.failure(error));
              }
          );
    };
   }
  static request(response) {
    //console.log('request:' + response);
    return { type: userConstants.LOGIN_REQUEST, response }
  }
  static reset(response) {
    //console.log('request:' + response);
    return { type: userConstants.LOGOUT, response }
  }
  static success(response) {
    //console.log('success:' + response);
    return { type: userConstants.LOGIN_SUCCESS, response }
  }
  static failure(error) {
    //console.log('failure:' + error);
    return { type: userConstants.LOGIN_FAILURE, error }
  }
}

export default userActions;
