import React, {Component} from "react";

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBBtn
} from 'mdbreact';

class UserCardFilter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    }
    this._onSelectChange = this._onSelectChange.bind(this);
  }

  componentDidMount() {
    this.setState(prevState => ({
        loaded: true
    }));
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;

    this.props.searchComponent.setState({
        filters: filters,
        page: 0
    }, () => {
        this.props.searchComponent.loadUserCardCollection();
    });
  }

  render() {
    let component = this;
    return (
      <div className="filter-card">
        <h5>Filter</h5>
        <MDBRow>
          <MDBCol size="2">
            <MDBSelect
                search
                options={this.props.searchComponent.state.usercardCategoriesFilter}
                selected="Wähle eine Kategorie"
                label="Kategorie"
                id="field_usercard_category"
                name="field_usercard_category"
                getValue={(e) => {
                  this._onSelectChange({
                    target: {
                      name: 'field_usercard_category',
                      value: e,
                    },
                  });
                }}
            />
          </MDBCol>
        </MDBRow>
      </div>
    )
  }

}

export default UserCardFilter;
