import React, { Component } from 'react';
import {
  MDBSideNavLink,
  MDBIcon,
  MDBBadge
} from 'mdbreact';

import PermissionHelper from "../../../helpers/PermissionHelper";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'

import NewsActions from "./actions/NewsActions";


class NewsMenuLink extends Component {

  constructor(props) {
    super(props);

    this.state= {
      newNewsCounter: 0,
      filters: {}
    }


    this._onClick = this._onClick.bind(this);
    this._onLoadNewsCounter = this._onLoadNewsCounter.bind(this);
  }

  componentDidMount() {

    this._onLoadNewsCounter();

  }


  _onLoadNewsCounter() {
    var component = this;


    if(PermissionHelper._hasPermission('ignore locations') === false) {
      var selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));
      var filters = component.state.filters;
      filters.field_location = selectedLocation;
      component.setState({
        filters: filters
      });
    }

    NewsActions._get('All', this.state.filters, 1).then(response => {
       //console.log(response.data.entity);
      //console.log(response.data.result);

      var newNewsCounter = 0;
      if(response.data.newNewsCounter) {
        newNewsCounter = response.data.newNewsCounter;
      }

      component.setState({
        newNewsCounter: newNewsCounter
      });
    });
  }

  _onClick() {
    this._onLoadNewsCounter();
    this.props.onClick();
  }

  render() {
    return (
      <MDBSideNavLink topLevel to='/news' onClick={this._onClick}>
        <FontAwesomeIcon icon={AllLightIcons.faNewspaper} />
        News
        {this.state.newNewsCounter > 0 &&
          <MDBBadge color="danger" className="ml-2">{this.state.newNewsCounter}</MDBBadge>
        }
      </MDBSideNavLink>
    )
  }

}

export default NewsMenuLink;
