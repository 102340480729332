import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon
} from 'mdbreact';


import TreatmentActions from "./actions/TreatmentActions";
import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';




class TreatmentResultRow extends React.Component {

  constructor(props) {
    super(props);
    this._onComplete = this._onComplete.bind(this);
    this._onDelete = this._onDelete.bind(this);
    this._onSentReviewNotification = this._onSentReviewNotification.bind(this);
  }

  _onComplete() {
    let component = this;

    let values = {
      nid: this.props.result.nid,
      field_is_completed: 1
    }

    const r = window.confirm("Abschliesen Bestätigen");
    if (r === true) {
      component.props.searchComponent.setState({
        loaded: false,
      });
      TreatmentActions._patch(values).then(response => {
        component.props.searchComponent.loadTreatmentCollection();
      });
    }
  }


  _onSentReviewNotification() {
    let component = this;

    let values = {
      nid: this.props.result.nid,
      field_review_notification: 1
    }

    const r = window.confirm("Bewertungserrinerung schicken?");
    if (r === true) {
      component.props.searchComponent.setState({
        loaded: false,
      });
      TreatmentActions._patch(values).then(response => {
        component.props.searchComponent.loadTreatmentCollection();
      });
    }
  }

  _onDelete() {
    let component = this;

    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      component.props.searchComponent.setState({
        loaded: false,
      });
      TreatmentActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.loadTreatmentCollection();
        });
    }
  }

  render() {
    return (
      <tr>
        <td>{this.props.result.node.field_treatment_number[0].value}</td>
        <td>{moment.unix(this.props.result.created).format("DD.MM.YYYY HH:mm")}</td>
        <td>{this.props.result.treatment_type.name[0].value}</td>

        <td className="text-right">

          <div className="action-bar">



            {false && PermissionHelper._hasPermission('restful patch Treatment') === true &&
              <>
                {this.props.result.node.field_is_completed[0].value == '0' &&
                  <MDBBtn color="green" className="btn-details mr-2" onClick={this._onComplete}><MDBIcon far icon="check-square" /></MDBBtn>
                }
              </>
            }



            <MDBBtn color={(this.props.result.node.field_review_notification[0] && this.props.result.node.field_review_notification[0].value) ? 'green' : 'red'} className="btn-details mr-2" onClick={(this.props.result.node.field_review_notification[0] && this.props.result.node.field_review_notification[0].value) ? 'null' : this._onSentReviewNotification}>
              <MDBIcon icon="pen-square" />
            </MDBBtn>


            <MDBLink className="btn btn-info" to={`/treatment/${this.props.result.nid}`}>
              <MDBIcon icon="bars" />
            </MDBLink>

            {PermissionHelper._hasPermission('restful delete Treatment') === true &&
              <>
                <MDBBtn color="red" className="btn-delete mr-2" onClick={this._onDelete}><MDBIcon far icon="trash-alt" /></MDBBtn>
              </>
            }

          </div>


        </td>
      </tr>
    );
  }
}

export default TreatmentResultRow;
