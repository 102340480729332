import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody
} from "mdbreact";

import Select from 'react-select'


import MDBFileupload from 'mdb-react-fileupload';

import { Editor } from '@tinymce/tinymce-react';

import FaqActions from "./actions/FaqActions";
import Image from "../image/Image";

import moment from 'moment';
import 'moment/locale/de';


class FaqForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      entity: this.props.entity,
      modal: false,
      nid: false,
      title: '',
      body: '',
      field_faq_category: '',
      faqCategoriesFilter: this.props.searchComponent.state.faqCategoriesFilter,
      loaded: true
    }

    this.faqCategoryFilterRef = React.createRef();
    this.fileInputRef = React.createRef();

    this._onToggleModal = this._onToggleModal.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onChangeHandleFile = this._onChangeHandleFile.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onHandleEditorChange = this._onHandleEditorChange.bind(this);
  }

  componentDidMount() {

    let component = this;
    if(this.props.entity) {


      var faqCategories = this.props.searchComponent.state.faqCategories;
      var faqCategoriesFilter = new Array();
      if(faqCategories) {
        Object.entries(faqCategories).forEach(function(item, key){
          var optionItem = {
            label: item[1].name[0].value,
            value: item[0]
          }
          faqCategoriesFilter.push(optionItem);
          //console.log(item);
        });
      }


      this.setState(prevState => ({
        nid: this.props.entity.nid[0].value,
        title: this.props.entity.title[0].value,
        body: this.props.entity.body[0].value,
        field_faq_category: [this.props.entity.field_faq_category[0].target_id],
        faqCategoriesFilter: faqCategoriesFilter
      }))
    }
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value) {
      value = e.target.value;
    } else {
      value = null;
    }
    this.setState({
      [e.target.name]: [e.target.value],
    });
  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    this.setState(prevState => ({
      loaded: false,
    }))


    if(!this.state.body) {
      alert('Bitte gib eine Faqtext ein.');
      return;
    }


    if(!this.state.nid) {

    }


    let values = {
      nid: this.state.nid,
      title: this.state.title,
      body: this.state.body,
      field_faq_category: this.state.field_faq_category,
    }

    if(!this.state.nid) {
      FaqActions._post(values)
        .then(response => {
          component.props.searchComponent.loadFaqCollection();
          component._onToggleModal();
          component._onFormReset();
        });
    } else {
      FaqActions._patch(values)
        .then(response => {
          component.props.searchComponent.loadFaqCollection();
          component._onToggleModal();
        });
    }
  }

  _onInputChange(e) {
    //console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onChangeHandleFile = (file) => {
    var component = this;

    var reader = new FileReader();
    reader.addEventListener("load", function () {
         var dataString = reader.result;
         var uint8 = new Uint8Array(dataString);
         //console.log(uint8);
         FaqActions._uploadImage(uint8).then(response => {
           component.setState({
             field_image: response.data.fid[0].value
           });
         });
    }, false);


    if(file) {
      reader.readAsArrayBuffer(file);
      this.setState({
        imageUrl: URL.createObjectURL(file)
      });
    }
  }

  _onFormReset() {

    if(this.faqCategoryFilterRef.current) {
      this.faqCategoryFilterRef.current.select.clearValue();
    }

    if(this.fileInputRef.current) {
      this.fileInputRef.current.resetPreview();
    }

    var faqCategories = this.props.searchComponent.state.faqCategories;
    var faqCategoriesFilter = new Array();
    if(faqCategories) {
      Object.entries(faqCategories).forEach(function(item, key){
        var optionItem = {
          label: item[1].name[0].value,
          value: item[0]
        }
        faqCategoriesFilter.push(optionItem);
      });
    }



    this.setState(prevState => ({
      loaded: true,
      nid: false,
      title: '',
      body: '',
      field_faq_category: '',
      faqCategoriesFilter: faqCategoriesFilter,
    }))
  }

  _onHandleEditorChange = (content, editor) => {
     //console.log('Content was updated:', content);
     this.setState({
       body: content,
     });
   }

  render() {

    let component = this;

    var defaultCategoryValue = false;

    if(this.state.nid) {
      if(this.props.searchComponent.state.faqCategoriesFilter.find(x => x.value === this.state.field_faq_category.toString())) {
        defaultCategoryValue = { label: this.props.searchComponent.state.faqCategoriesFilter.find(x => x.value === this.state.field_faq_category.toString()).label, value: this.state.field_faq_category.toString() }
      }
    }

    return (
      <>
      {!this.state.entity &&
        <>
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neue FAQ hinzufügen</MDBBtn>
          <hr />
        </>
      }

      {this.state.entity &&
        <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
      }


      <MDBModal size="lg" isOpen={this.state.modal} toggle={this._onToggleModal}>
        <MDBModalHeader toggle={this._onToggleModal}>Faqverwaltung</MDBModalHeader>
        <MDBModalBody>
          <form id="faq-add-form" onSubmit={this._onSubmit}>
            <MDBRow>

              <MDBCol size="12">

                <MDBRow>
                  <MDBCol>
                    <MDBInput outline label="Titel" name="title" id="title" onChange={this._onInputChange} value={this.state.title} required />
                  </MDBCol>

                  <MDBCol>
                    <div className="md-form form-group md-outline">
                    <Select
                      ref={this.faqCategoryFilterRef}
                      required
                      className="react-select"
                      classNamePrefix="react-select"
                      placeholder="Kategorie"
                      options={this.state.faqCategoriesFilter}
                      defaultValue={defaultCategoryValue}
                      isClearable={true}
                      onChange={(value) => {

                        if(value) {
                          value = value.value;
                        } else {
                          value = '';
                        }

                        this._onSelectChange({
                          target: {
                            name: 'field_faq_category',
                            value: value,
                          },
                        });
                      }} />

                  </div>
                  </MDBCol>
                </MDBRow>


                <Editor
                 required
                 apiKey='2es7wv2n49blwza8a9hwyax3y4cov70297nz2yeuwq4w3dad'
                 value={this.state.body}
                 initialValue={this.state.body}
                 init={{
                   height: 170,
                   menubar: false,
                   plugins: [
                     'advlist autolink lists link image charmap print preview anchor',
                     'searchreplace visualblocks code fullscreen',
                     'insertdatetime media table paste code help wordcount'
                   ],
                   toolbar:
                     'undo redo | formatselect | bold italic backcolor | \
                     alignleft aligncenter alignright alignjustify | \
                     bullist numlist outdent indent | removeformat | help'
                 }}
                 onEditorChange={this._onHandleEditorChange}
               />

             <hr />

               <MDBRow>
                 <MDBCol>
                   {!this.state.entity &&
                     <MDBBtn color="danger" className="mr-2" onClick={this._onFormReset}><MDBIcon icon="sync mr-2" /> Zurücksetzen</MDBBtn>
                   }

                   <MDBBtn color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>


                 </MDBCol>
               </MDBRow>

              </MDBCol>
            </MDBRow>
          </form>
        </MDBModalBody>
      </MDBModal>
      </>

    );
  }
}

export default FaqForm;
