import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody
} from "mdbreact";

import MDBFileupload from 'mdb-react-fileupload';

import { Editor } from '@tinymce/tinymce-react';

import ScanActions from "./actions/ScanActions";
import Image from "../image/Image";

import moment from 'moment';
import 'moment/locale/de';


class ScanForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      nid: false,
      title: '',
      body: '',
      field_file: false,
      field_customer: this.props.nid,
      loaded: true,
      modal: false,
      file: ''
    }

    this.fileInputRef = React.createRef();

    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onChangeHandleFile = this._onChangeHandleFile.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onHandleEditorChange = this._onHandleEditorChange.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();



    //console.log(this.state.file);
    if(this.state.file.type != 'application/pdf') {
      alert('Bitte PDF Datei auswählen.');
      return;
    }


    /*
    if(!this.state.body) {
      alert('Bitte Beschreibungstext einfügen.');
      return;
    }*/


    component.props.searchComponent.setState(prevState => ({
      loaded: false,
    }))

    let values = {
      nid: this.state.nid,
      title: this.state.title,
      body: this.state.body,
      field_file: this.state.field_file,
      field_customer: this.state.field_customer,
    }

    if(!this.state.nid) {
      ScanActions._post(values)
        .then(response => {
          component.props.searchComponent.loadScanCollection();
          component._onFormReset();
          component._onToggleModal();
        });
    } else {
      ScanActions._patch(values)
        .then(response => {
          component.props.searchComponent.loadScanCollection();
          component._onFormReset();
          component._onToggleModal();
        });
    }
  }

  _onInputChange(e) {
    //console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onChangeHandleFile = (file) => {
    var component = this;

    this.setState({
      file: file
    });

    var reader = new FileReader();
    reader.addEventListener("load", function () {
         var dataString = reader.result;
         var uint8 = new Uint8Array(dataString);
         //console.log(uint8);
         ScanActions._uploadFile(uint8).then(response => {
           component.setState({
             field_file: response.data.fid[0].value
           });
         });
    }, false);


    if(file) {
      reader.readAsArrayBuffer(file);
      this.setState({
        fileUrl: URL.createObjectURL(file)
      });
    }
  }

  _onFormReset() {



    if(this.fileInputRef.current) {
      this.fileInputRef.current.resetPreview();
    }


    this.setState(prevState => ({
      loaded: true,
      nid: false,
      title: '',
      body: '',
      field_file: false,
    }))
  }

  _onHandleEditorChange = (content, editor) => {
     //console.log('Content was updated:', content);
     this.setState({
       body: content,
     });
   }


  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    let component = this;
    return (

      <>
      <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neuen Scan erfassen</MDBBtn>
      <MDBModal isOpen={this.state.modal} toggle={this._onToggleModal}>
        <MDBModalHeader toggle={this._onToggleModal}>Neuen Scan erfassen</MDBModalHeader>
        <MDBModalBody>
          <MDBCard className='mt-0'>
            <form id="scan-add-form" onSubmit={this._onSubmit}>
              <MDBRow>
                <MDBCol size="12">
                    <MDBFileupload
                        maxFileSize="16M"
                        ref={this.fileInputRef}
                        getValue={this._onChangeHandleFile}
                        />

                </MDBCol>
                <MDBCol size="12">

                  <MDBInput outline label="Titel" name="title" id="title" onChange={this._onInputChange} value={this.state.title} required />


                  <Editor
                   apiKey='2es7wv2n49blwza8a9hwyax3y4cov70297nz2yeuwq4w3dad'
                   value={this.state.body}
                   initialValue={this.state.body}
                   init={{
                     height: 170,
                     menubar: false,
                     plugins: [
                       'advlist autolink lists link image charmap print preview anchor',
                       'searchreplace visualblocks code fullscreen',
                       'insertdatetime media table paste code help wordcount'
                     ],
                     toolbar:
                       'undo redo | formatselect | bold italic backcolor | \
                       alignleft aligncenter alignright alignjustify | \
                       bullist numlist outdent indent | removeformat | help'
                   }}
                   onEditorChange={this._onHandleEditorChange}
                 />

               <hr />

                 <MDBRow>
                   <MDBCol>
                     <MDBBtn color="danger" onClick={this._onFormReset} className="mr-2"><MDBIcon icon="sync mr-2" /> Zurücksetzen</MDBBtn>


                       {!this.state.field_file &&
                         <MDBBtn color="primary" type="submit" disabled><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                       }

                       {this.state.field_file &&
                         <MDBBtn color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                       }

                   </MDBCol>
                 </MDBRow>

                </MDBCol>
              </MDBRow>
            </form>
          </MDBCard>
        </MDBModalBody>
      </MDBModal>
      </>


    );
  }
}

export default ScanForm;
