import React, {Component} from 'react';

import TaskForm from "./TaskForm";
import TaskActions from "./actions/TaskActions";
import PermissionHelper from "../../../helpers/PermissionHelper";
import TaskResultRow from "./TaskResultRow";
import TaskFilter from "./TaskFilter";
import Pager from 'react-pager';


import {
  MDBSpinner,
  MDBTable,
  MDBDataTable
} from 'mdbreact';

import moment from 'moment';
import 'moment/locale/de';

import config from '../../../config';

import socketIOClient from "socket.io-client";
// Load the `base` value from config and set it as a constant.
const DRUPAL_NOTIFY_ROOT = `${config.notify_base}`;

class TaskSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      employeeFilter: [],
      locationsFilter: [],
      filters: {
        field_employee: null,
        field_location: (this.props.route == '/taskteam') ? localStorage.getItem('selectedLocation') : null,
        field_status: null,
      },
    }

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
  }


  componentDidMount() {

    var component = this;

    this.loadTaskCollection();



    if(this.props.route == '/taskteam') {
      const endpoint = DRUPAL_NOTIFY_ROOT;
      const socket = socketIOClient(endpoint);
      socket.on('connect', function() {

        var authMessage = {
          authToken: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).nodejs_auth_token : null,
          contentTokens: ''
        };
        //console.log(authMessage);
        socket.emit('authenticate', authMessage);

        ///console.log('websocket notify connect');
      });

      var joinMessage = {
        channel: 'nodejs_notify',
        contentToken: ''
      };

      socket.emit('join-token-channel', joinMessage);
      socket.emit('subscribe', 'nodejs_notify');

      //console.log(socket);
      socket.on('message', function(e){
        //console.log(e);
        //console.log('message recive');


        //console.log(component.props.application);
        switch(e.callback) {
          case 'team_task_post':
            component.loadTaskCollection();
            break;
          case 'team_task_patch':
            component.loadTaskCollection();
            break;
          default:
            break;
        }

      });

      socket.on('disconnect', function() {
        //console.log('websocket notify disconnect');
      });
    }

  }


  loadTaskCollection() {

      var component = this;

      component.setState({
        collectionLoaded: false
      });

      TaskActions._get('All', this.state.filters, this.state.page).then(response => {
        //  console.log(response.data.entity);
        //console.log(response.data.entity);

        var locations = response.data.locations;
        var locationsFilter = new Array();
        if(locations) {
          Object.entries(locations).forEach(function(item, key){
            var optionItem = {
              text: item[1].title[0].value,
              value: item[0].toString()
            }

            /*
            if(PermissionHelper._hasPermission('ignore locations') === false) {
              var userLocations = new Array();
              var userLocationsObject = JSON.parse(localStorage.getItem('user'));
              userLocationsObject = userLocationsObject.field_location;
              Object.entries(userLocationsObject).forEach(function(location, k){
                //console.log(location[1].target_id);
                userLocations.push(location[1].target_id.toString());
              })

              var hasLocation = userLocations.includes(item[0].toString());
              //console.log(hasLocation);
              //console.log(item[0]);
              if(hasLocation) {
                locationsFilter.push(optionItem);
              }
              //console.log(userLocations);
            } else {
              locationsFilter.push(optionItem);
            }*/

            locationsFilter.push(optionItem);


            //console.log(item);
          });
        }

        if(this.state.locationsFilter.length == 0) {
          component.setState({
            locationsFilter: locationsFilter,
            locations: locations,
          });
        }


        var employeeCollection = response.data.employeeCollection;
        var employeeFilter = new Array();
        if(employeeCollection) {
          Object.entries(employeeCollection).forEach(function(item, key){

            var uid = item[1].uid[0].value;
            var optionItem = {
              label:  item[1].field_firstname[0].value + ' ' + item[1].field_lastname[0].value,
              value: uid.toString()
            }
            employeeFilter.push(optionItem);
          });
        }

        if(this.state.employeeFilter.length == 0) {
          component.setState({
            employeeFilter: employeeFilter,
            employeeCollection: employeeCollection,
          });
        }

        component.setState({
          result: Object.entries(response.data.entity),
          loaded: true,
          total: response.data.total,
          collectionLoaded: true
        });
      });
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadTaskCollection();
    });
  }

  render() {

    var component = this;

    var locationTaskPermission = true;

    if(!PermissionHelper._hasPermission('config location task') && this.props.route == '/taskteam') {
      locationTaskPermission = false;
    }

    return (
      <>


          {locationTaskPermission && this.state.loaded &&
            <TaskForm searchComponent={component} route={this.props.route} />
          }


          {this.state.loaded &&
            <>
              <TaskFilter searchComponent={component}  />
            </>
          }

          {!this.state.loaded &&
            <div className="text-center">
              <MDBSpinner key={'complete-loader-' + this.state.nid} red />
            </div>
          }





        {component.state.result.length == 0 && this.state.loaded &&
          <div className="empty">keine offenen Aufgaben</div>
        }


          {component.state.loaded && (
            component.state.result.map(function(item, i){
              //console.log(item)
              return (
                <div key={i}>
                <h5>{moment(item[0]).format("dddd, DD MMMM YYYY")}</h5>
                <MDBTable responsive hover striped className={`${!component.state.collectionLoaded ? "loading" : ""}`}>
                  <thead>
                    <tr>
                      <th className='th-lg'>
                        Zeit
                      </th>
                      <th className='th-lg'>
                        Bemerkung
                      </th>
                      <th className='th-sm'>
                      </th>
                      <th className='th-sm'>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {component.state.loaded && (
                      item[1].map(function(row, j){
                        //console.log(row)
                        return (
                          <TaskResultRow result={row}  key={'task-result-view-' + row.nid} listComponent={component} route={component.props.route} />
                        )
                      })
                    )}

                  </tbody>
                </MDBTable>
              </div>
              )
            })
          )}

          {component.state.loaded && component.state.total > 10 && (
            <>
              <hr />
              <Pager
                    total={Math.ceil(this.state.total / this.state.limit)}
                    current={this.state.page}
                    visiblePages={this.state.visiblePage}
                    titles={{ first: '<|', last: '>|' }}
                    className="pagination-sm pull-right"
                    onPageChanged={this._onHandlePageChanged}
                />
            </>
          )}




      </>
    );
  }
}

export default TaskSearch;
