import React, { Component } from 'react';
import {
  MDBSideNavLink,
  MDBIcon,
  MDBBadge
} from 'mdbreact';

import PermissionHelper from "../../../helpers/PermissionHelper";

import TaskActions from "./actions/TaskActions";

import socketIOClient from "socket.io-client";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'

// Import our config file.
import config from '../../../config.js';

// Load the `base` value from config and set it as a constant.
const DRUPAL_NOTIFY_ROOT = `${config.notify_base}`;

class TaskTeamMenuLink extends Component {

  constructor(props) {
    super(props);

    this.state= {
      newTaskCounter: 0,
      filters: {
        field_location: localStorage.getItem('selectedLocation') ? JSON.parse(localStorage.getItem('selectedLocation')) : null,
        field_employee: null,
        field_status: null
      },
    }


    this._onClick = this._onClick.bind(this);
    this._onLoadTaskCounter = this._onLoadTaskCounter.bind(this);
  }

  componentDidMount() {

    if (JSON.parse(localStorage.getItem('user')) == null){
        return;
    }

    this._onLoadTaskCounter();



    var component = this;
    const endpoint = DRUPAL_NOTIFY_ROOT;
    const socket = socketIOClient(endpoint);


    socket.on('connect', function() {

      var authMessage = {
        authToken: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).nodejs_auth_token : null,
        contentTokens: ''
      };
      //console.log(authMessage);
      socket.emit('authenticate', authMessage);

      ///console.log('websocket notify connect');
    });

    var joinMessage = {
      channel: 'nodejs_notify',
      contentToken: ''
    };

    socket.emit('join-token-channel', joinMessage);
    socket.emit('subscribe', 'nodejs_notify');


    var joinMessage = {
      channel: 'nodejs_notify',
      contentToken: ''
    };

    socket.emit('join-token-channel', joinMessage);
    socket.emit('subscribe', 'nodejs_notify');


    //console.log(socket);
    socket.on('message', function(e){
      //console.log(e);
      //console.log('message recive');


      //console.log(component.props.application);

      switch(e.callback) {
        default:

          component._onLoadTaskCounter();
          break;
      }

    });

    socket.on('disconnect', function() {
      //console.log('websocket notify disconnect');
    });

  }


  _onLoadTaskCounter() {
    var component = this;




    if(PermissionHelper._hasPermission('ignore locations') === false) {
      var selectedLocation = JSON.parse(localStorage.getItem('selectedLocation'));
      var filters = component.state.filters;
      filters.field_location = selectedLocation;
      component.setState({
        filters: filters
      });
    }

    TaskActions._get('All', this.state.filters, 0).then(response => {
       //console.log(response);

      var newTaskCounter = 0;
      if(response.data.total) {
        newTaskCounter = response.data.total;
      }


      component.setState({
        newTaskCounter: newTaskCounter
      });
    });
  }

  _onClick() {
    this._onLoadTaskCounter();
    this.props.onClick();
  }

  render() {
    return (
      <MDBSideNavLink topLevel to='/taskteam' onClick={this._onClick}>
        <FontAwesomeIcon icon={AllLightIcons.faTasks} />
        Teamaufgaben
        {this.state.newTaskCounter > 0 &&
          <MDBBadge color="danger" className="ml-2 task-badge">{this.state.newTaskCounter}</MDBBadge>
        }
      </MDBSideNavLink>
    )
  }

}


export default TaskTeamMenuLink;
