import React, { Component } from 'react';

/** import config **/
import config from '../../../config';

import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBTable,
  MDBTableHead,
  MDBTableBody,
  MDBSpinner
} from 'mdbreact';

import EmployeeActions from './actions/EmployeeActions';

import { withRouter } from 'react-router';


import moment from 'moment';
import 'moment/locale/de';


class EmployeeBirthday extends Component {

  constructor(props) {
    super(props);

    this.state = {
      result: [],
      filters: {
        field_location: JSON.parse(localStorage.getItem('selectedLocation'))
      }
    }

  }

  componentDidMount() {

    EmployeeActions._getBirthdays('All', this.state.filters).then(response => {
      //console.log(response);
      this.setState({
        result: response.data.result,
        loaded: true
      });
    });

  }

  render() {

    var component = this;
    var today = moment().format("YYYY-MM-DD");


    return (
      <section id="employee-birthday">
        <MDBCard>
        <MDBCardBody>
          <MDBCardTitle><MDBIcon icon="birthday-cake mr-2" /> Geburtstage</MDBCardTitle>
          <MDBCardText tag="div">
            {!this.state.loaded &&
              <div className="text-center">
                <MDBSpinner red />
              </div>
            }
            {this.state.loaded &&
              <MDBTable>
                <MDBTableHead>
                  <tr>
                    <th>Mitarbeiter</th>
                    <th>In Tagen</th>
                  </tr>
                </MDBTableHead>
                <MDBTableBody>

                  {true && (
                    Object.entries(component.state.result).map(function(row, j){
                      //console.log(row)
                      var birthday = moment(row[1].field_birthday[0].value);
                      var age = moment(today).diff(birthday, 'years');
                      var nextBirthday = moment(birthday).add(age + 1, 'years');
                      return (
                        <tr key={'birthday-' + j}>
                          <td>{row[1].field_firstname[0].value} {row[1].field_lastname[0].value}</td>

                          <td>
                            {nextBirthday.diff(today, 'days') == 1 &&
                              <span>
                               morgen
                              </span>
                            }

                            {nextBirthday.diff(today, 'days') == 365 &&
                              <span>
                               heute
                              </span>
                            }

                            {nextBirthday.diff(today, 'days') > 1 && nextBirthday.diff(today, 'days') < 365 &&
                              <span>
                                in {nextBirthday.diff(today, 'days')} Tagen
                              </span>
                            }
                          </td>
                        </tr>
                      )
                    })
                  )}


                </MDBTableBody>
              </MDBTable>
            }
          </MDBCardText>
        </MDBCardBody>
      </MDBCard>
      </section>
    );
  }
}

export default withRouter(EmployeeBirthday);
