import React, { Component } from 'react';


import {
  MDBCard,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBSpinner
} from 'mdbreact';
import AppointmentActions from '../appointment/actions/AppointmentActions';
import moment from 'moment';
import Pager from 'react-pager';
import AppointmentComment from "../comment/CommentModalSearch";


class CustomerAppointments extends Component {


  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      isLoading: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      filters: {
        field_location: null,
        field_from: moment(new Date()).subtract(3, 'years').format('YYYY-MM-DDT00:00'),
        field_to: moment(new Date()).add(3, 'years').format('YYYY-MM-DDT24:00'),
        field_sort: 'nf_field_from.field_from_value',
        field_sort_direction: 'desc',
        field_limit: 10,
        field_view: 'customer',
        field_product: true,
        field_searchterm: '',
        field_customer: this.props.customerId
      }
    }

    let refresehInterval = null;

    this._onStateReset = this._onStateReset.bind(this);
    this._onLoadCollection = this._onLoadCollection.bind(this);
    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
  }

  componentDidMount() {
    let component = this;
    component._onLoadCollection();
  }

  componentWillUnmount() {
    //clearInterval(this.refresehInterval);
  }


  _onStateReset() {
    let component = this;

    // Reset state
    component.setState(prevState => ({
      loaded: false,
      isLoading: true,
    }))
  }



  _onLoadCollection() {



    let component = this;

    component._onStateReset();

    /** build filter query **/
    AppointmentActions._get('All', this.state.filters, this.state.page).then(response => {
      var result = [];
      if(response.data.result) {
        result = Object.entries(response.data.result);
      }

      component.setState({
        result: result,
        loaded: true,
        isLoading: false,
        total: response.data.total
      });
    });
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this._onLoadCollection();
    });
  }

  render() {
    let component = this;
    return (
      <section id="customer-appointments">


              {!this.state.loaded &&
                <div className="text-center"><MDBSpinner key={'appointment-loader'} red /></div>
              }


              {this.state.loaded &&
              <MDBTable>
                <MDBTableHead>
                  <tr>
                    <th>Uhrzeit</th>
                    <th>Mitarbeiter</th>
                    <th>Behandlung</th>
                    <th>Standort</th>
                    <th>Notiz</th>
                  </tr>
                </MDBTableHead>

                  {component.state.result.length > 0 && this.state.loaded &&
                    <MDBTableBody>
                        {true && (
                          component.state.result.map(function(row, j){
                            //console.log(row)
                            return (
                              <tr>
                                <td>{moment(row[1].node['field_from'][0].value).format('DD.MM.YYYY HH:mm')} - {moment(row[1].node['field_to'][0].value).format('HH:mm')}</td>
                                <td>
                                  {row[1].employee &&
                                    <>{row[1].employee['field_firstname'][0].value} {row[1].employee['field_lastname'][0].value}</>
                                  }
                                </td>
                                <td>
                                  {row[1].product &&
                                    <>{row[1].product['title'][0].value}</>
                                  }
                                </td>
                                <td>
                                  {row[1].location &&
                                    <>{row[1].location['title'][0].value}</>
                                  }
                                </td>
                                <td>
                                  <>{row[1].node['body'][0].value}</>
                                </td>
                                <td>
                                  <div className="action-bar">
                                    <AppointmentComment showImportant={false} entityId={row[1].node['nid'][0].value} entityType="node" fieldName="field_appointent_comment" commentType="appointment_comment" />
                                  </div>
                              </td>
                              </tr>
                            )
                          })
                        )}

                        {component.state.loaded && component.state.total > 10 && (
                          <>
                            <hr />
                            <Pager
                                  total={Math.ceil(this.state.total / this.state.limit)}
                                  current={this.state.page}
                                  visiblePages={this.state.visiblePage}
                                  titles={{ first: '<|', last: '>|' }}
                                  className="pagination-sm pull-right"
                                  onPageChanged={this._onHandlePageChanged}
                              />
                          </>
                        )}

                    </MDBTableBody>
                  }


              </MDBTable>
            }



      </section>
    );
  }
}

export default CustomerAppointments;
