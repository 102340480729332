import React, { Component } from 'react';
import Pager from 'react-pager';
import LocationResultRow from "./LocationResultRow";
import LocationActions from "./actions/LocationActions";
import Image from "../image/Image";
import LocationForm from "./LocationForm";
import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../config';

import PermissionHelper from "../../../helpers/PermissionHelper";



class LocationSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: [],
      page: 0,
      limit: 20,
      total: 0,
      visiblePage: 5,
      locationCategoriesFilter: [],
      locationsFilter: [],
      filters: {
        field_location_category: null
      }
    }


    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadLocationCollection();
    });
  }

  componentDidMount(){
    this.loadLocationCollection();
  }

  loadLocationCollection() {


      var component = this;

      /** build filter query **/

      LocationActions._get('All', this.state.filters, this.state.page).then(response => {
        //  console.log(response.data.entity);
        //console.log(response.data.result);


        var locationCategories = response.data.locationCategories;
        var locationCategoriesFilter = new Array();
        if(locationCategories) {
          Object.entries(locationCategories).forEach(function(item, key){
            var optionItem = {
              checked: (item[0] == component.state.filters.field_location_category) ? true : false,
              text: item[1].name[0].value,
              value: item[0]
            }
            locationCategoriesFilter.push(optionItem);
            //console.log(item);
          });
        }


        var locations = response.data.locations;
        var locationsFilter = new Array();
        if(locations) {
          Object.entries(locations).forEach(function(item, key){
            var optionItem = {
              text: item[1].title[0].value,
              value: item[0]
            }
            locationsFilter.push(optionItem);
            //console.log(item);
          });
        }

        //console.log(locationCategoriesFilter);

        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }

        if(this.state.locationCategoriesFilter.length == 0) {
          component.setState({
            locationCategoriesFilter: locationCategoriesFilter,
            locationCategories: locationCategories,
          });
        }

        if(this.state.locationsFilter.length == 0) {
          component.setState({
            locationsFilter: locationsFilter,
            locations: locations,
          });
        }

        component.setState({
          result: result,
          loaded: true,
          total: response.data.total
        });

      });
  }

  render() {
    let component = this;
    return (
      <>

        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'location-loader'} red /></div>
        }




        {component.state.loaded && (
          <>
          {/*<LocationFilter searchComponent={this} /><hr />*/}

          {PermissionHelper._hasPermission('restful post Location') === true && false &&
            <>
              <LocationForm searchComponent={this} ref={this.formRef} />
            </>
          }

            { component.state.result.length > 0 && (
              <MDBRow>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <LocationResultRow result={row[1]}  key={'location-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
              </MDBRow>
            )}

            {component.state.result.length == 0 &&
              <div className="empty">Keine passenden Location</div>
            }

            {component.state.loaded && component.state.total > 10 && false && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default LocationSearch;
