import React, {
  Component
} from "react";

import {DebounceInput} from 'react-debounce-input';
import PermissionHelper from "../../../helpers/PermissionHelper";

import Select from 'react-select'


import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBBtn
} from 'mdbreact';


class TaskFilter extends Component {

  constructor(props) {
    super(props);

    this.state = {
      taskFilter: null,
      statusOptions: [
        {
          label: "Offen",
          value: '0'
        },
        {
          label: "Erledigt",
          value: '1'
        }
      ]
    }

    this._onCheckboxChange = this._onCheckboxChange.bind(this);
    this._onSelectChange = this._onSelectChange.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onDirectionChange = this._onDirectionChange.bind(this);
  }

  componentDidMount() {

  }

  _onCheckboxChange(e) {
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value) {
      value = e.target.value;
    } else {
      value = null;
    }
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _onDirectionChange(e) {
    var name = e.target.name;
    var value = e.target.value;

    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;

    this._updateSearchFilter(filters);
  }

  _onInputChange(e) {
    //console.log(e);
    var name = e.target.name;
    var value = e.target.value;
    var filters = this.props.searchComponent.state.filters;
    filters[name] = value;


    this._updateSearchFilter(filters);
  }

  _updateSearchFilter(filters) {

    var component = this;

    //console.log(filters);
    component.props.searchComponent.setState({
        filters: filters,
        collectionLoaded: false,
        page: 0
    }, () => {
        var taskFilter = component.props.searchComponent.state;
        localStorage.setItem('taskFilter', JSON.stringify(taskFilter));
        component.props.searchComponent.loadTaskCollection();
    });
  }

  render() {
    let component = this;
    return (
      <div className="filter-card">

        <MDBRow>
          {PermissionHelper._hasPermission('ignore task') === true &&
            <MDBCol md="3" xs="12">


                <Select
                  className="react-select"
                  classNamePrefix="react-select"
                  placeholder="Mitarbeiter wählen"
                  options={this.props.searchComponent.state.employeeFilter}
                  defaultValue={(this.props.searchComponent.state.filters.field_employee) ? { label: this.props.searchComponent.state.employeeFilter.find(x => x.value === this.props.searchComponent.state.filters.field_employee.toString()).label, value: this.props.searchComponent.state.filters.field_employee.toString() } : false}
                  isClearable={true}
                  onChange={(value) => {

                    if(value) {
                      value = value.value;
                    } else {
                      value = '';
                    }

                    this._onSelectChange({
                      target: {
                        name: 'field_employee',
                        value: value,
                      },
                    });
                  }}
                />



            </MDBCol>
          }



          <MDBCol md="3" xs="12">


            <Select
              className="react-select"
              classNamePrefix="react-select"
              placeholder="Status"
              options={this.state.statusOptions}
              defaultValue={(this.props.searchComponent.state.filters.field_status) ? { label: component.state.statusOptions.find(x => x.value === this.props.searchComponent.state.filters.field_status.toString()).label, value: this.props.searchComponent.state.filters.field_status.toString() } : false}
              isClearable={true}
              onChange={(value) => {

                if(value) {
                  value = value.value;
                } else {
                  value = '';
                }

                this._onSelectChange({
                  target: {
                    name: 'field_status',
                    value: value,
                  },
                });
              }}
            />


          </MDBCol>




        </MDBRow>
        <hr />


      </div>
    )
  }

}

export default TaskFilter;
