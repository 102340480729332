import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody
} from "mdbreact";

import MDBFileupload from 'mdb-react-fileupload';

import { Editor } from '@tinymce/tinymce-react';


import GalleryActions from "./actions/GalleryActions";
import Image from "../image/Image";

import moment from 'moment';
import 'moment/locale/de';


class GalleryForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      title: '',
      body: '',
      field_image: '',
      field_entity: this.props.entity,
      loaded: true,
      modal: false,
      file: '',
    }

    this.fileInputRef = React.createRef();

    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onChangeHandleFile = this._onChangeHandleFile.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);
  }




  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    this.setState(prevState => ({
      loaded: false,
    }))

    if(this.state.file.type != 'image/jpg' && this.state.file.type != 'image/png' && this.state.file.type != 'image/jpeg') {
      alert('Please choose Image file.');
      return;
    }

    let values = {
      title: this.state.title,
      body: this.state.body,
      field_image: this.state.field_image,
      field_entity: this.state.field_entity,
    }

    GalleryActions._post(values)
      .then(response => {
        component.props.searchComponent.loadGalleryCollection();
        component._onFormReset();
        component._onToggleModal();
      });
  }

  _onInputChange(e) {
    //console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onChangeHandleFile = (file) => {
    var component = this;

    this.setState({
      file: file
    });

    var reader = new FileReader();
    reader.addEventListener("load", function () {
         var dataString = reader.result;
         var uint8 = new Uint8Array(dataString);
         console.log(uint8);
         GalleryActions._uploadImage(uint8).then(response => {
           component.setState({
             field_image: response.data.fid[0].value
           });
         });
    }, false);


    if(file) {
      reader.readAsArrayBuffer(file);
      this.setState({
        imageUrl: URL.createObjectURL(file)
      });
    }
  }

  _onFormReset() {

    if(this.fileInputRef.current) {
      this.fileInputRef.current.resetPreview();
    }

    window.scrollTo(0, 0);
    this.setState(prevState => ({
      loaded: true,
      title: '',
      body: '',
      field_image: '',
    }))
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    let component = this;
    return (

      <>
      <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neues Bild hochladen</MDBBtn>
      <MDBModal isOpen={this.state.modal} toggle={this._onToggleModal}>
        <MDBModalHeader toggle={this._onToggleModal}>Neues Bild hochladen</MDBModalHeader>
        <MDBModalBody>
          <MDBCard className='mt-0'>
            <form id="gallery-add-form" onSubmit={this._onSubmit}>
              <MDBRow>
                <MDBCol size="12">
                  <MDBFileupload ref={this.fileInputRef} getValue={this._onChangeHandleFile} maxFileSize="16M" />
                </MDBCol>
                <MDBCol size="12">
                  <MDBInput outline label="Titel" name="title" id="title" onChange={this._onInputChange} value={this.state.title} required />
                  <MDBInput outline label="Beschreibung" name="body" type="textarea" id="body" onChange={this._onInputChange} value={this.state.body} required />
                 <MDBRow>
                   <MDBCol>
                     <div className="form-action-bar">
                       <MDBBtn color="danger" className="mr-2" onClick={this._onFormReset}><MDBIcon icon="sync mr-2" /> Zurücksetzen</MDBBtn>
                         {!this.state.field_image &&
                           <MDBBtn color="primary" type="submit" disabled><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                         }

                         {this.state.field_image &&
                           <MDBBtn color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                         }
                     </div>
                   </MDBCol>
                 </MDBRow>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCard>
        </MDBModalBody>
      </MDBModal>
      </>


    );
  }
}

export default GalleryForm;
