import React, { Component } from 'react';
import Pager from 'react-pager';
import TreatmentResultRow from "./TreatmentResultRow";
import TreatmentActions from "./actions/TreatmentActions";
import Image from "../image/Image";
import TreatmentFilter from "./TreatmentFilter";
import TreatmentForm from "./TreatmentForm";
import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBLink,
  MDBBtn,
  MDBIcon,
  MDBCardImage
} from 'mdbreact';

import config from '../../../config';

class TreatmentSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: [],
      treatmentTypes: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      filters: {
        field_searchterm: null,
        field_customer: this.props.nid
      }
    }

    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    this.loadTreatmentCollection = this.loadTreatmentCollection.bind(this);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadTreatmentCollection();
    });
  }

  componentDidMount(){
    this.loadTreatmentCollection();
  }

  loadTreatmentCollection() {
    var component = this;

    component.setState({
      loaded: false,
      collectionLoaded: false,
    });

    /** build filter query **/
    TreatmentActions._get('All', this.state.filters, this.state.page).then(response => {
      var result = [];
      if(response.data.result) {
        result = Object.entries(response.data.result);
      }
      component.setState({
        result: result,
        treatmentTypes: response.data.treatmentTypes,
        treatmentTypesNew: response.data.treatmentTypesNew,
        channelOptions: response.data.channelOptions,
        loaded: true,
        collectionLoaded: true,
        total: response.data.total
      });
    });
  }

  render() {
    let component = this;
    return (
      <>
        {!this.state.loaded &&
          <div className="text-center"><MDBSpinner key={'treatment-loader'} red /></div>
        }

        {component.state.loaded && (
          <>
          <TreatmentForm searchComponent={this} ref={this.formRef} nid={this.props.nid} key={'treatment-form'} />
          <hr />
          {/*<TreatmentFilter searchComponent={this} /><hr />*/}
            {component.state.result.length > 0 && (
              <MDBTable responsive hover className={`${!component.state.collectionLoaded ? "loading" : ""}`}>
                <thead>
                  <tr>
                    <th className='th-lg'>
                      Behandlungs-Nr.
                    </th>
                    <th className='th-lg'>
                      Datum
                    </th>
                    <th className='th-lg'>
                      Behandlungsart
                    </th>
                    <th className='th-sm'>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <TreatmentResultRow result={row[1]}  key={'treatment-result-view-' + row[1].nid + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>
            )}
            {component.state.result.length == 0 &&
              <div className="empty">Keine Behandlungen vorhanden</div>
            }
            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}
      </>
    );
  }
}

export default TreatmentSearch;
