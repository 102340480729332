import React, {
  Component
} from 'react';

import VacationQuotaResultRow from "./VacationQuotaResultRow";
import Pager from 'react-pager';
import VacationQuotaFilter from "./VacationQuotaFilter";
import PermissionHelper from "../../../helpers/PermissionHelper";
import VacationQuotaActions from "./actions/VacationQuotaActions";
import VacationQuotaForm from "./VacationQuotaForm";

import {
  MDBSpinner,
  MDBTable,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBInput,
  MDBSelect,
  MDBCollapseHeader,
  MDBIcon,
  MDBCollapse,
  MDBPagination,
  MDBPageItem,
  MDBPageNav
} from 'mdbreact';

import moment from 'moment';
import 'moment/locale/de';

import config from '../../../config';



class VacationQuotaSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      visiblePage: 5,
      employeeFilter: [],
      filters: {
        field_employee: null,
        field_year: new Date().getFullYear(),
      },
      sortOptions: [
        {
          checked: true,
          text: 'Geändert',
          value: 'ufd.changed'
        },
        {
          text: 'Nachname',
          value: 'uf_lastname.field_lastname_value'
        }
      ],
      yearOptions: [
        {
          text: new Date().getFullYear()-1,
          value: new Date().getFullYear()-1
        },
        {
          checked: true,
          text: new Date().getFullYear(),
          value: new Date().getFullYear()
        },
        {
          text: new Date().getFullYear()+1,
          value: new Date().getFullYear()+1
        }
      ]
    }

    this.formRef = React.createRef();

    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    //console.log(props);
  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadVacationQuotaCollection();
    });
  }

  componentDidMount() {
    this.loadVacationQuotaCollection();
    //console.log(this);
  }

  loadVacationQuotaCollection() {


      var component = this;

      component.setState({
        collectionLoaded: false
      });

      /** build filter query **/
      VacationQuotaActions._get('All', this.state.filters, this.state.page).then(response => {
        //  console.log(response.data.entity);
        //console.log(response.data.result);


        var result = [];
        if(response.data.result) {
          result = Object.entries(response.data.result);
        }


        if(response.data.users) {
          var employeeFilter = new Array();

          Object.entries(response.data.users).forEach(function(item, key){
            var optionItem = {
              text: item[1].field_firstname[0].value + ' ' + item[1].field_lastname[0].value,
              value: item[1].uid[0].value
            }

            if(component.state.filters.field_employee == item[1].uid[0].value) {
              optionItem.checked = true;
            }

            employeeFilter.push(optionItem);
            component.setState({
              employeeFilter: employeeFilter,
            });
            //console.log(item);
          });

        }


        component.setState({
          result: result,
          loaded: true,
          collectionLoaded: true,
          total: response.data.total
        });

      });
  }

  render() {
    let component = this;
    return (
      <>

        {!this.state.collectionLoaded &&
          <div className="text-center"><MDBSpinner key={'vacationquota-loader'} red /></div>
        }

        {component.state.collectionLoaded && (
          <>
            {PermissionHelper._hasPermission('restful post VacationQuota') === true &&
              <>
                <VacationQuotaForm searchComponent={this} ref={this.formRef} />
              </>
            }

            <VacationQuotaFilter searchComponent={component} />
            <hr />
            { component.state.result.length > 0 && (
              <MDBTable responsive hover striped>
                <thead>
                  <tr>
                    <th className='th-lg'>
                      QutaNumber
                    </th>

                    <th className='th-lg'>
                      Employee
                    </th>
                    <th className='th-lg'>
                      Year
                    </th>
                    <th className='th-lg'>
                      Quota
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <VacationQuotaResultRow result={row[1]}  key={'vacationquota-result-view-' + row[1].uid  + row[1].changed} searchComponent={component} />
                      )
                    })
                  )}
                </tbody>
              </MDBTable>
            )}

            {component.state.result.length == 0 &&
              <div className="empty">Keine passenden Kontigente</div>
            }

            {component.state.loaded && component.state.total > 10 && (
              <>
                <hr />
                <Pager
                      total={Math.ceil(this.state.total / this.state.limit)}
                      current={this.state.page}
                      visiblePages={this.state.visiblePage}
                      titles={{ first: '<|', last: '>|' }}
                      className="pagination-sm pull-right"
                      onPageChanged={this._onHandlePageChanged}
                  />
              </>
            )}
          </>
        )}

      </>
    );
  }
}

export default VacationQuotaSearch;
