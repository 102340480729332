import React, { Component } from 'react';

import moment from 'moment';
import 'moment/locale/de';

import {
  MDBTable,
  MDBBtn,
  MDBCollapseHeader,
  MDBIcon,
  MDBCardBody,
  MDBSpinner,
  MDBAlert
} from 'mdbreact';


import CanvasDraw from "react-canvas-draw";
import mergeImages from 'merge-images';
import TreatmentActions from '../treatment/actions/TreatmentActions';



class SessionStencil extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      showSuccessMessage: false
    }


    this.stencilRef = React.createRef();

    this._onStencilClear = this._onStencilClear.bind(this);
    this._onStencilSave = this._onStencilSave.bind(this);
    this._onStancilUndo = this._onStancilUndo.bind(this);
  }




  componentDidMount() {


  }


  _onStencilClear(e) {
    e.preventDefault();
    this.stencilRef.current.clear();
  }

  _onStancilUndo(e) {
    e.preventDefault();
    this.stencilRef.current.undo();
  }

  _onStencilSave() {
    //e.preventDefault();
    var component = this;

    component.setState({
      isSaving: true,
      showSuccessMessage: false
    });

    var saveData = this.stencilRef.current.getSaveData();
    var drawImage = this.stencilRef.current.drawImage();
    //console.log(saveData);
    var drawings = this.stencilRef.current.canvas.drawing.toDataURL();
    var stencilBackground = this.props.stencil;

    mergeImages([drawings, stencilBackground]).then(function(b64) {

      //console.log(b64);


      var dataURI = b64;
      var BASE64_MARKER = ';base64,';
      var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
      var base64 = dataURI.substring(base64Index);
      var raw = window.atob(base64);
      var rawLength = raw.length;
      var array = new Uint8Array(new ArrayBuffer(rawLength));

      var i = 0;
      for(i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
      }

      TreatmentActions._uploadImage(array).then(response => {
        var values = {
          nid: component.props.nid,
          field_stencil: response.data.fid[0].value
        }

        TreatmentActions._patch(values).then(response => {
          //component.props.searchComponent.props.treatmentDetail._loadTreatmentDetails();
          component.setState({
            isSaving: false,
            showSuccessMessage: true
          });
        });
      });



    });

  }

  render() {

    return(
      <>
      <div id="treatmentStencilCanvas">



          {this.state.isSaving &&
            <div className="text-center"><MDBSpinner key={'session-stencil-loader'} red /></div>
          }


          {this.state.showSuccessMessage &&
          <MDBAlert color="success" dismiss>
            Die Schablone wurde gespeichert.
          </MDBAlert>
          }

            <div className="d-flex call-to-action">
              <MDBBtn color="danger" disabled={this.state.isSaving ? true : false}  className="btn-clear" onClick={this._onStancilUndo}><MDBIcon icon="sync mr-2" /> Letzte Aktion widerufen</MDBBtn>
              <hr />
              <MDBBtn color="danger" disabled={this.state.isSaving ? true : false}  className="btn-clear" onClick={this._onStencilClear}><MDBIcon icon="times mr-2" /> Alles löschen</MDBBtn>
              <hr />
              <MDBBtn color="primary" disabled={this.state.isSaving ? true : false} className="btn-save" onClick={this._onStencilSave}><MDBIcon far icon="save mr-2" /> Speichern</MDBBtn>
            </div>


          <div className="treamtement-stencil-canvas">


          <CanvasDraw id="treatmentStencilCanvas" canvasWidth={750} canvasHeight={1061} ref={this.stencilRef} imgSrc={this.props.stencil} brushRadius={1} lazyRadius={0} brushColor={'#ff0000'}  />


            {/**
             * 
             * <CanvasDraw id="treatmentStencilCanvas" ref={this.stencilRef} imgSrc={this.props.stencil} canvasWidth={'750px'} canvasHeight={'1061px'} brushRadius={1} lazyRadius={0} brushColor={'#ff0000'} />
             */}
          </div>
      </div>

      </>
    )
  }
}

export default SessionStencil;
