var backendBase = 'https://api.intranet.venus-beauty.ch/';
if(window.location.href.indexOf("stage") > -1) {
  backendBase = 'https://api.dev.intranet.venus-beauty.ch/';
}
if(window.location.href.indexOf("dev") > -1) {
  backendBase = 'https://api.dev.intranet.venus-beauty.ch/';
}


var notifyBase = 'https://notify.intranet.venus-beauty.ch/';
if(window.location.href.indexOf("stage") > -1) {
  notifyBase = 'https://notify.dev.intranet.venus-beauty.ch/';
}
if(window.location.href.indexOf("dev") > -1) {
  notifyBase = 'https://notify.dev.intranet.venus-beauty.ch/';
}

export default  {
  backend_base: backendBase,
  notify_base: notifyBase,
};
