import React, { Component } from 'react';

import {
  MDBSpinner,
  MDBLightbox,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBIcon,
  MDBBadge,
  MDBModal,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader
} from 'mdbreact';

import Pager from 'react-pager';

import CommentActions from "./actions/CommentActions";
import CommentForm from "./CommentForm";
import CommentResultRow from "./CommentResultRow";

import "../../../assets/css/sections/comment.css";

import PermissionHelper from "../../../helpers/PermissionHelper";


class CommentModalSearch extends Component {

  constructor(props) {
    super(props);

    this.state = {
      filters: {
        entity_id: this.props.entityId,
        entity_type: this.props.entityType,
      },
      loaded: false,
      result: [],
      page: 0,
      limit: 10,
      total: 0,
      countImportant: 0,
      visiblePage: 5,
    }


    this.formRef = React.createRef();

    this.loadCommentCollection = this.loadCommentCollection.bind(this);
    this._onHandlePageChanged = this._onHandlePageChanged.bind(this);
    this._onModalToggle = this._onModalToggle.bind(this);
  }

  componentDidMount(){
    this.loadCommentCollection();
  }

  _onModalToggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }



  loadCommentCollection() {
    var component = this;


    /** build filter query **/
    CommentActions._get('All', this.state.filters, this.state.page).then(response => {
      var result = [];
      var countImportant = 0;
      if(response.data.result) {
        result = Object.entries(response.data.result);
      }

      if(response.data.countImportant) {
        countImportant = response.data.countImportant;
      }



      component.setState({
        result: result,
        loaded: true,
        collectionLoaded: true,
        total: response.data.total
      });



    });


  }

  _onHandlePageChanged(newPage) {
    this.setState({
        page: newPage
    }, () => {
        this.loadCommentCollection();
    });
  }



  render() {
    let component = this;
    return (
        <>

        <MDBBtn onClick={this._onModalToggle} color="primary" size="md">
            <MDBIcon far icon="comments" />
              {this.state.total > 0 &&
                <MDBBadge color="danger" className="ml-2">{this.state.total}</MDBBadge>
              }
        </MDBBtn>

        <MDBModal isOpen={this.state.modal} toggle={this._onModalToggle} size="lg">
          <MDBModalHeader toggle={this._onModalToggle}>Kommentare</MDBModalHeader>
          <MDBModalBody className="text-left">

            <CommentForm showImportant={this.props.showImportant} ref={this.formRef} entityId={this.props.entityId} entityType="node" fieldName={this.props.fieldName} commentType={this.props.commentType} searchComponent={this} />
            <hr />

              {component.state.result.length == 0 &&
                <div className="empty">Keine Kommentare vorhanden</div>
              }

              {!this.state.loaded &&
                <div className="text-center"><MDBSpinner key={'comment-loader'} red /></div>
              }

            {component.state.result.length > 0 && this.state.loaded &&
              <ul id="comments-list" class="comments-list">
                  {true && (
                    component.state.result.map(function(row, j){
                      //console.log(row)
                      return (
                        <CommentResultRow result={row[1]}  key={'comment-result-view-' + row[1].cid} searchComponent={component} />
                      )
                    })
                  )}

                  {component.state.loaded && component.state.total > 10 && (
                    <>
                      <hr />
                      <Pager
                            total={Math.ceil(this.state.total / this.state.limit)}
                            current={this.state.page}
                            visiblePages={this.state.visiblePage}
                            titles={{ first: '<|', last: '>|' }}
                            className="pagination-sm pull-right"
                            onPageChanged={this._onHandlePageChanged}
                        />
                    </>
                  )}

              </ul>
            }


        </MDBModalBody>
      </MDBModal>


        </>
    );
  }
}

export default CommentModalSearch;
