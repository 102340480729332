import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody
} from "mdbreact";

import SubscriptionActions from "./actions/SubscriptionActions";

class SubscriptionForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      hasSubmitted: false,
      entity: this.props.entity,
      modal: false,
      nid: false,
      title: '',
      field_subscription_category: '',
      field_treatment_type: (this.props.treatmentType) ? [this.props.treatmentType] : '',
      field_customer: this.props.nid,
      field_counter: '',
      subscriptionCategories: this.props.searchComponent.state.subscriptionCategories,
      treatmentTypes: this.props.searchComponent.state.treatmentTypes,
      loaded: true
    }

    this.subscriptionCategoryFilterRef = React.createRef();
    this.treatmentTypeFilterRef = React.createRef();

    this._onToggleModal = this._onToggleModal.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
  }

  componentDidMount() {

    let component = this;

    if(this.props.entity) {


      var subscriptionCategories = this.props.searchComponent.state.subscriptionCategories;
      var subscriptionCategoriesFilter = new Array();
      if(subscriptionCategories) {
        Object.entries(subscriptionCategories).forEach(function(item, key){
          var optionItem = {
            checked: (item[0] == component.props.entity.field_subscription_category[0].target_id) ? true : false,
            text: item[1].name[0].value,
            value: item[0]
          }
          subscriptionCategoriesFilter.push(optionItem);
          //console.log(item);
        });
      }

      var treatmentTypes = this.props.searchComponent.state.treatmentTypes;
      var treatmentTypeFilter = new Array();
      if(treatmentTypes) {
        Object.entries(treatmentTypes).forEach(function(item, key){
          var optionItem = {
            checked: (item[0] == component.props.entity.field_treatment_type[0].target_id) ? true : false,
            text: item[1].name[0].value,
            value: item[0]
          }
          treatmentTypeFilter.push(optionItem);
          //console.log(item);
        });
      }


      this.setState(prevState => ({
        nid: this.props.entity.nid[0].value,
        title: this.props.entity.title[0].value,
        field_subscription_category: [this.props.entity.field_subscription_category[0].target_id],
        field_customer: [this.props.entity.field_customer[0].target_id],
        field_counter: this.props.entity.field_counter[0].value,
        field_treatment_type: [this.props.entity.field_treatment_type[0].target_id],
        treatmentTypes: treatmentTypeFilter,
        subscriptionCategories: subscriptionCategoriesFilter
      }))
    }
  }

  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value[0]) {
      value = e.target.value[0];
    } else {
      value = null;
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();

    if(component.state.hasSubmitted) {
      return;
    }


    this.setState(prevState => ({
      loaded: false,
      hasSubmitted: true,
    }))


    let values = {
      nid: this.state.nid,
      title: this.state.title,
      field_customer: this.state.field_customer,
      field_subscription_category: this.state.field_subscription_category,
      field_treatment_type: this.state.field_treatment_type,
      field_counter: this.state.field_counter
    }

    if(!this.state.nid) {
      SubscriptionActions._post(values)
        .then(response => {
          component.setState(prevState => ({
            hasSubmitted: false,
          }))
          component._onFormReset();
          component.props.searchComponent.loadSubscriptionCollection();
          component._onToggleModal();
        });
    } else {
      SubscriptionActions._patch(values)
        .then(response => {
          component.setState(prevState => ({
            hasSubmitted: false,
          }))
          component._onFormReset();
          component.props.searchComponent.loadSubscriptionCollection();
          component._onToggleModal();
        });
    }
  }

  _onInputChange(e) {
    //console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onFormReset() {

    var component = this;

    console.log(component.subscriptionCategoryFilterRef);

    if(component.subscriptionCategoryFilterRef.current) {
      component.subscriptionCategoryFilterRef.current.selectOption();
    }

    if(component.treatmentTypeFilterRef.current) {
      component.treatmentTypeFilterRef.current.selectOption();
    }



    this.setState(prevState => ({
      hasSubmitted: false,
      loaded: true,
      nid: false,
      title: '',
      field_subscription_category: '',
      field_treatment_type: '',
      field_counter: ''
    }))
  }



  render() {

    let component = this;
    return (
      <>
      {!this.state.entity &&
        <>
          <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neues Abo erfassen</MDBBtn>
          <hr />
        </>
      }

      {this.state.entity &&
        <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
      }


      <MDBModal size="lg" isOpen={this.state.modal} toggle={this._onToggleModal}>
        <MDBModalHeader toggle={this._onToggleModal}>Aboverwaltung</MDBModalHeader>
        <MDBModalBody>
          <form id="subscription-add-form" onSubmit={this._onSubmit}>
            {!this.props.treatmentType &&
              <MDBRow>
                <MDBCol className="text-left">
                  <MDBSelect
                    ref={this.treatmentTypeFilterRef}
                    selected="Bitte auswählen"
                    name="field_treatment_type"
                    options={this.state.treatmentTypes}
                    error='wrong'
                    success='right'
                    required
                    getValue={(e) => {
                      this._onSelectChange({
                        target: {
                          name: 'field_treatment_type',
                          value: e,
                        },
                      });
                    }}
                    label="Behandlungsart"
                  />
                </MDBCol>
              </MDBRow>
            }

            <MDBRow>
              <MDBCol className="text-left">
                <MDBSelect
                  search
                  ref={this.subscriptionCategoryFilterRef}
                  selected="Bitte auswählen"
                  name="field_subscription_category"
                  options={this.state.subscriptionCategories}
                  error='wrong'
                  success='right'
                  required
                  getValue={(e) => {
                    this._onSelectChange({
                      target: {
                        name: 'field_subscription_category',
                        value: e,
                      },
                    });
                  }}
                  label="Abo"
                />
              </MDBCol>
            </MDBRow>

            <hr />

              <MDBRow>
                <MDBCol>

                  {!this.state.entity &&
                    <MDBBtn color="primary" className="mr-2" onClick={this._onFormReset}><MDBIcon icon="sync mr-2" /> Reset</MDBBtn>
                  }

                  <MDBBtn disabled={( this.state.hasSubmitted  ? true : false)} color="primary"  type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>


                </MDBCol>
              </MDBRow>

          </form>
        </MDBModalBody>
      </MDBModal>
      </>

    );
  }
}

export default SubscriptionForm;
