import {
  START_YEAR,
  NUM_OF_YEARS,
  MONTH_NAMES,
  MONTHS_PER_YEAR,
  QUARTERS_PER_YEAR,
  MONTHS_PER_QUARTER,
  NUM_OF_MONTHS,
  MAX_TRACK_START_GAP,
  MAX_ELEMENT_GAP,
  MAX_MONTH_SPAN,
  MIN_MONTH_SPAN,
  MAX_NUM_OF_SUBTRACKS,
} from './constants'

import { fill, hexToRgb, colourIsLight, addMonthsToYear, addMonthsToYearAsDate, nextColor, randomTitle } from './utils'

export const buildQuarterCells = (year) => {
  const v = []
  for (let i = 0; i < QUARTERS_PER_YEAR * NUM_OF_YEARS; i += 1) {
    const quarter = (i % 4) + 1
    const startMonth = i * MONTHS_PER_QUARTER
    const s = addMonthsToYear(year, startMonth)
    const e = addMonthsToYear(year, startMonth + MONTHS_PER_QUARTER)
    v.push({
      id: `${s.year}-q${quarter}`,
      title: `Q${quarter} ${s.year}`,
      start: new Date(`${s.year}-${s.month}-01`),
      end: new Date(`${e.year}-${e.month}-01`),
    })
  }
  return v
}

export const buildMonthCells = (year) => {
  const v = []
  for (let i = 0; i < MONTHS_PER_YEAR * NUM_OF_YEARS; i += 1) {

    const startMonth = i
    const start = addMonthsToYearAsDate(year, startMonth)
    const end = addMonthsToYearAsDate(year, startMonth + 1)

    //console.log(end);

    v.push({
      id: `m${startMonth}-y${year}`,
      title: MONTH_NAMES[i % 12],
      start,
      end,
    })
  }
  return v
}


export const buildDaysCells = (year) => {

  //console.log(year);

  const v = []

  for (let i = 1; i <= MONTHS_PER_YEAR * NUM_OF_YEARS; i += 1) {



    const startMonth = i

    const r = addMonthsToYear(year, startMonth);
    //console.log(r);

    var monthDays = daysInMonth(startMonth,year);



    for (let j = 1; j <= monthDays; j += 1) {
      var startDay = j;
      //console.log(i);



      const start = new Date(`${year}-${startMonth}-${j}`);



      let month = startMonth;
      let day = j+1;

      //console.log(monthDays);
      //console.log(j);

      if(monthDays == j) {
        month = startMonth + 1;
        day = '01';
        //console.log(`${START_YEAR}-${month}-${day}`);
      }



      const end = new Date(`${year}-${month}-${day}`);


      v.push({
        id: `m${startMonth}-d${startDay}-y${year}`,
        title: j.toString(),
        start,
        end,
      })
    }
  }


  //console.log(v);
  return v
}



export const buildWeeksCells = (year) => {
  const v = []
  let j = 1;
  for (let i = 1; i <= 52; i += 1) {

    const start = new Date(year, 0, 1 + ((i - 1) * 7));
    const end = new Date(year, 0, 1 + ((i - 1) * 7) + 7);

    v.push({
      id: `kw${j}`,
      title: j,
      start,
      end,
    })

    j++;

  }


  //console.log(v);
  return v
}


export const daysInMonth = (month, year) => {
    return new Date(year, month, 0).getDate();
}

export const buildTimebar = (year) => [
  {
    id: 'quarters',
    title: 'Quartal',
    cells: buildQuarterCells(year),
    style: {},
  },
  {
    id: 'months',
    title: 'Monat',
    cells: buildMonthCells(year),
    useAsGrid: true,
    style: {},
  },
  /*
  {
    id: 'kw',
    title: 'KW',
    cells: buildWeeksCells(),
    useAsGrid: true,
    style: {},
  },*/
  {
    id: 'days',
    title: 'Tag',
    cells: buildDaysCells(year),
    useAsGrid: true,
    style: {},
  }
]

export const buildElement = ({ trackId, start, end, i }) => {
  const bgColor = nextColor()
  const color = colourIsLight(...hexToRgb(bgColor)) ? '#000000' : '#ffffff'
  return {
    id: `t-${trackId}-el-${i}`,
    title: randomTitle(),
    start,
    end,
    style: {
      backgroundColor: `#${bgColor}`,
      color,
      borderRadius: '4px',
      boxShadow: '1px 1px 0px rgba(0, 0, 0, 0.25)',
      textTransform: 'capitalize',
    },
  }
}

export const buildTrackStartGap = () => Math.floor(Math.random() * MAX_TRACK_START_GAP)
export const buildElementGap = () => Math.floor(Math.random() * MAX_ELEMENT_GAP)

export const buildElements = trackId => {
  const v = []
  const start = addMonthsToYearAsDate(START_YEAR, 1)
  const end = addMonthsToYearAsDate(START_YEAR, 1 + 1)
  v.push(
    buildElement({
      trackId,
      start,
      end,
      trackId,
    })
  )

  return v
}

export const buildSubtrack = (trackId, subtrackId) => ({
  id: `track-${trackId}-${subtrackId}`,
  title: `Subtrack ${subtrackId}`,
  elements: buildElements(subtrackId),
})

export const buildTrack = trackId => {
  const tracks = fill(Math.floor(Math.random() * MAX_NUM_OF_SUBTRACKS) + 1)
  return {
    id: `track-${trackId}`,
    title: `Track ${trackId}`,
    elements: buildElements(trackId),
    tracks,
    // hasButton: true,
    // link: 'www.google.com',
    isOpen: false,
  }
}
