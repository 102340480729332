import React, { Component } from 'react';
import {
  MDBCard,
  MDBSpinner,
  MDBLink,
  MDBNavLink,
  MDBTabContent,
  MDBTabPane,
  MDBNav,
  MDBNavItem,
  MDBIcon,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCol,
  MDBRow,
  MDBBtn,
  MDBAnimation,
  MDBBadge
} from 'mdbreact';
import CustomerComment from "../comment/CommentSearch";
import CommentImportantCounter from "../comment/CommentImportantCounter";
import CustomerScan from "../scan/ScanSearch";
import TreatmentSearch from "../treatment/TreatmentSearch";
import CustomerGallery from "../gallery/GallerySearch";
import CustomerActions from './actions/CustomerActions';
import CustomerForm from './CustomerForm';
import PermissionHelper from "../../../helpers/PermissionHelper";
import { history } from '../../../helpers/history';

import UserCardSearch from "../usercard/UserCardSearch";
import CustomerAppointments from "./CustomerAppointments";

import SubscriptionSearch from "../subscription/SubscriptionSearch";


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import * as AllLightIcons from '@fortawesome/pro-light-svg-icons'

import NumberFormat from 'react-number-format';


import moment from 'moment';
import 'moment/locale/de';

/** import styles **/
import '../../../assets/css/sections/customer.css';



class CustomerDetail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      activeItem: "1",
      loaded: false,
      entity: null,
      locations: [],
      genders: [],
      countImportant: 0,
      nid: this.props.match.params.id,
      channel: ''
    };


    this.commentImportantCounterRef = React.createRef();

    this._onDelete = this._onDelete.bind(this);
    this._onTabToggle = this._onTabToggle.bind(this);
    this._loadCustomer = this._loadCustomer.bind(this);
  }

  componentDidMount() {
    var component = this;

    this._loadCustomer();
  }

  componentDidUpdate(prevProps, prevState) {
         /**
         * this is the initial render
         * without a previous prop change
         */
        if(prevProps == undefined) {
            return false
        }

        /**
         * new Project in town ?
         */
        if (this.state.nid != this.props.match.params.id) {
           this.setState({nid: this.props.match.params.id});
           this.componentDidMount();
        }

    }


  _loadCustomer() {

    var component = this;

    CustomerActions._get(this.props.match.params.id).then(response => {
      //console.log(response);



      var genders = response.data.genders;
      var genderFilters = new Array();
      if(genders) {
        Object.entries(genders).forEach(function(item, key){
          //console.log(item);
          var optionItem = {
            label: item[1].name,
            value: item[1].id.toString()
          }
          genderFilters.push(optionItem);
          //console.log(item);
        });
      }

        component.setState({
          genderFilters: genderFilters,
          genders: genders,
        });





        var channels = response.data.channels;
        var channelsFilters = new Array();
        if(channels) {
          Object.entries(channels).forEach(function(item, key){
            //console.log(item);
            var optionItem = {
              label: item[1].name,
              value: item[1].id.toString()
            }
            channelsFilters.push(optionItem);
            //console.log(item);
          });
        }

          component.setState({
            channelsFilters: channelsFilters,
            channels: channels,
          });





          var communicationTypes = response.data.communicationTypes;
          var communicationTypesFilters = new Array();
          if(communicationTypes) {
            Object.entries(communicationTypes).forEach(function(item, key){
              //console.log(item);
              var optionItem = {
                label: item[1].name,
                value: item[1].id.toString()
              }
              communicationTypesFilters.push(optionItem);
              //console.log(item);
            });
          }

            component.setState({
              communicationTypes: communicationTypes,
              communicationTypesFilters: communicationTypesFilters,
            });


        this.setState({
          entity: response.data.entity,
          locations: response.data.locations,
          locationsFilter: response.data.locationsFilter,
          genders: response.data.genders,
          image: response.data.image,
          channelOptions: response.data.channelOptions,
          channels: response.data.channels,
          channel: response.data.channel,
          loaded: true
        });

    });
  }

  _onTabToggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab
      });
    }
  };

  _onDelete() {
    const r = window.confirm("Löschen Bestätigen");
    if(r === true){
      this.setState({
        loaded: false
      });
      CustomerActions._delete(this.props.match.params.id).then(response => {
        history.push('/customer');
      });
    }
  }

  render() {

    let component = this;

    return (
      <section id="customer-detail" {...(this.state.loaded ? {key: this.state.entity.changed[0].value} : {})}>

        {!this.state.loaded &&
          <div className="text-center">
            <MDBSpinner red />
          </div>
        }

        {this.state.loaded &&
            <MDBCard>
              <MDBCardBody tag="div">
                <MDBCardTitle>
                  <MDBRow>
                    <MDBCol size="6" className="">
                      <MDBIcon icon="users mr-2" /> {this.state.entity.field_firstname[0].value} {this.state.entity.field_lastname[0].value}
                    </MDBCol>
                    <MDBCol size="6">

                      <div className="action-bar">
                        <MDBNavLink to={"/customer"} className="mr-2">
                          <MDBBtn size="sm" color="info">
                            <MDBIcon icon="chevron-left" />
                          </MDBBtn>
                        </MDBNavLink>



                        <CustomerForm  key={'customer-edit-form-' + this.state.entity.nid[0].value + this.state.entity.changed[0].value} entity={this.state.entity} locations={this.state.locations} locationsFilter={this.state.locationsFilter}  customerDetail={this}  />

                      {PermissionHelper._hasPermission('restful delete Customer') === true &&
                        <>
                            <MDBBtn size="sm" color="danger" onClick={this._onDelete}>
                              <MDBIcon far icon="trash-alt" />
                            </MDBBtn>
                        </>
                      }

                      </div>


                    </MDBCol>
                  </MDBRow>
                </MDBCardTitle>
                <MDBCardText tag="div">
                <div className="row">
                  <div className="col-md-4">
                    <h3>Persönliche Daten</h3>
                    <div className="item">
                      <div className="label">Anrede</div>
                      <div className="value">
                        {this.state.entity.field_gender[0] &&
                          this.state.genders.map(function(row, j){
                            //console.log(row);
                            if(row.id == component.state.entity.field_gender[0].target_id) {
                              return row.name;
                            }
                          })
                        }

                      </div>
                    </div>
                    <div className="item">
                      <div className="label">Vorname</div>
                      <div className="value">
                        {this.state.entity.field_firstname[0] &&
                          <>{this.state.entity.field_firstname[0].value}</>
                        }
                      </div>
                    </div>
                    <div className="item">
                      <div className="label">Nachname</div>
                      <div className="value">
                        {this.state.entity.field_lastname[0] &&
                          <>{this.state.entity.field_lastname[0].value}</>
                        }
                      </div>
                    </div>
                    <div className="item">
                      <div className="label">Beruf</div>
                        <div className="value">
                          {this.state.entity.field_job[0] &&
                            <>{this.state.entity.field_job[0].value}</>
                          }
                        </div>
                    </div>
                    <div className="item">
                      <div className="label">Geburtstag</div>
                        <div className="value">
                          {this.state.entity.field_birthday[0] &&
                            <>{moment(this.state.entity.field_birthday[0].value).format('DD.MM.YYYY')}</>
                          }
                        </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <h3>Kontaktdaten</h3>
                    <div className="item">
                      <div className="label">E-Mail</div>
                        <div className="value">
                          {this.state.entity.field_email[0] &&
                            <>{this.state.entity.field_email[0].value}</>
                          }
                        </div>
                    </div>
                    {this.state.entity.field_phone[0] &&
                      <div className="item">
                        <div className="label">Festnetz</div>
                          <div className="value">
                              <NumberFormat displayType={'text'} format="### ### ## ##" mask="_" value={this.state.entity.field_phone[0].value}/>
                          </div>
                      </div>
                    }

                    <div className="item">
                      <div className="label">Mobil</div>
                        <div className="value">


                          {this.state.entity.field_mobile[0] && this.state.entity.field_mobile[0].value.substring(0,3) == '+49' &&
                            <><NumberFormat displayType={'text'} format="+## ### ### ## ##" mask="_" value={this.state.entity.field_mobile[0].value}/></>
                          }

                          {this.state.entity.field_mobile[0] && this.state.entity.field_mobile[0].value.substring(0,3) == '+33' &&
                            <><NumberFormat displayType={'text'} format="+## ### ### ###" mask="_" value={this.state.entity.field_mobile[0].value}/></>
                          }

                          {this.state.entity.field_mobile[0] && this.state.entity.field_mobile[0].value.substring(0,3) != '+49' && this.state.entity.field_mobile[0].value.substring(0,3) != '+33' &&
                            <><NumberFormat displayType={'text'} format="### ### ## ##" mask="_" value={this.state.entity.field_mobile[0].value}/> </>
                          }


                        </div>
                    </div>
                    <div className="item">
                      <div className="label">Adresse</div>

                        <div className="value">
                          {this.state.entity.field_street[0] &&
                            <>{this.state.entity.field_street[0].value}<br /></>
                          }
                          {this.state.entity.field_zip[0] &&
                            <>{this.state.entity.field_zip[0].value} </>
                          }
                          {this.state.entity.field_city[0] &&
                            <>{this.state.entity.field_city[0].value}</>
                          }
                        </div>


                    </div>
                  </div>
                  <div className="col-md-4">
                    <h3>Interna</h3>
                      <div className="item">
                        <div className="label">Kundennummer</div>
                        <div className="value">{this.state.entity.field_customer_number[0].value}</div>
                      </div>
                      {this.state.entity.field_channel[0] &&
                        <div className="item">
                          <div className="label">Channel</div>
                          <div className="value">{this.state.channel}</div>
                        </div>
                      }
                      {this.state.entity.field_channel[0] && this.state.entity.field_channel[0].target_id == 32 &&
                        <div className="item">
                          <div className="label">Channel</div>
                          <div className="value">{this.state.entity.field_channel_other[0].value}</div>
                        </div>
                      }
                      <div className="item">
                        <div className="label">Standorte</div>
                        <div className="value">
                          {true && (
                            this.state.locations.map(function(row, j){
                              //console.log(row)
                              return (
                                <span key={'location-' + j }>{row.title[0].value}, </span>
                              )
                            })
                          )}
                        </div>
                      </div>
                  </div>
                </div>
                <div className="customer-tabs">
                  <MDBNav tabs>
                    <MDBNavItem>
                      <MDBNavLink link to="#" active={this.state.activeItem === "1"} onClick={this._onTabToggle("1")} role="tab">
                        <div className="icon">
                          <FontAwesomeIcon icon={AllLightIcons.faClipboardListCheck} />
                        </div>
                        Behandlungen
                      </MDBNavLink>
                    </MDBNavItem>

                    <MDBNavItem>
                      <MDBNavLink link to="#" active={this.state.activeItem === "2"} onClick={this._onTabToggle("2")} role="tab">
                        <div className="icon">
                          <FontAwesomeIcon icon={AllLightIcons.faFilePdf} />
                        </div>
                        Scans
                      </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink link to="#" active={this.state.activeItem === "3"} onClick={this._onTabToggle("3")} role="tab">
                        <div className="icon">
                          <FontAwesomeIcon icon={AllLightIcons.faCommentAltDots} />
                          <CommentImportantCounter ref={this.commentImportantCounterRef} entityType="node" fieldName="field_customer_comment" commentType="customer_comment" entityId={this.state.entity.nid[0].value}  />
                        </div>
                        Bemerkungen
                    </MDBNavLink>
                    </MDBNavItem>
                    <MDBNavItem>
                      <MDBNavLink link to="#" active={this.state.activeItem === "4"} onClick={this._onTabToggle("4")} role="tab">
                        <div className="icon">
                          <FontAwesomeIcon icon={AllLightIcons.faImages} />
                        </div>
                        Galerie
                      </MDBNavLink>
                    </MDBNavItem>

                    <MDBNavItem>
                      <MDBNavLink link to="#" active={this.state.activeItem === "5"} onClick={this._onTabToggle("5")} role="tab">
                        <div className="icon">
                          <FontAwesomeIcon icon={AllLightIcons.faImages} />
                        </div>
                        Abonnements
                      </MDBNavLink>
                    </MDBNavItem>



                  </MDBNav>
                  <MDBTabContent className="card-no" activeItem={this.state.activeItem}>
                    <MDBTabPane tabId="1" role="tabpanel">
                      <TreatmentSearch nid={this.state.entity.nid[0].value} />
                    </MDBTabPane>
                    <MDBTabPane tabId="2" role="tabpanel">
                      <CustomerScan nid={this.state.entity.nid[0].value} />
                    </MDBTabPane>
                    <MDBTabPane id="customer-comment" tabId="3" role="tabpanel">
                      <CustomerComment showImportant={true} commentImportantCounter={this.commentImportantCounterRef} ref={this.customerCommentRef} entityId={this.state.entity.nid[0].value} entityType="node" fieldName="field_customer_comment" commentType="customer_comment" />
                    </MDBTabPane>
                    <MDBTabPane id="customer-gallery" tabId="4" role="tabpanel">
                      <CustomerGallery nid={this.state.entity.nid[0].value} />
                    </MDBTabPane>

                    <MDBTabPane id="customer-subscription" tabId="5" role="tabpanel">
                      <SubscriptionSearch nid={this.state.entity.nid[0].value} />
                    </MDBTabPane>




                  </MDBTabContent>
                </div>
                </MDBCardText>
              </MDBCardBody>
            </MDBCard>
        }
      </section>
    );
  }
}

//export default Customers;
export default CustomerDetail;
