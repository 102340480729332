import { Component } from 'react';

class PermissionHelper extends Component {
  constructor(props) {
    super(props);

    this._hasPermission = this._hasPermission.bind(this);
  }

  static _hasPermission(permissionName) {
    var hasPermission = false;
    //console.log(permissionName);

    const user = JSON.parse(localStorage.getItem('user'));
    if(user) {
      const permissions = Object.values(user.permissions);
      console.log(permissions);
      // Nachher
      permissions.forEach(function(item){
        //console.log(item);
        if(permissionName == item) {
          hasPermission = true;
        }
      });
    }

    return hasPermission;
  }
}

export default PermissionHelper;
