import React, {useState,useEffect} from 'react';
import {
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBLink,
  MDBView,
  MDBMask,
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
  MDBRow,
  MDBIcon
} from 'mdbreact';

import FaqActions from "./actions/FaqActions";
import FaqForm from "./FaqForm";
import FaqComment from "../comment/CommentSearch.jsx";
import Image from "../image/Image";

import PermissionHelper from "../../../helpers/PermissionHelper";

import moment from 'moment';
import 'moment/locale/de';



class FaqResultRow extends React.Component {

  constructor(props) {
    super(props);

    this.faqCommentRef = React.createRef();

    this._onDelete = this._onDelete.bind(this);
  }




  _onDelete(e) {
    var component = this;
    const r = window.confirm("Löschen Bestätigen");
    if (r === true) {
      FaqActions._delete(this.props.result.nid)
        .then(response => {
          component.props.searchComponent.formRef.current._onFormReset();
          component.props.searchComponent.loadFaqCollection();
        });
    }
  }


  render() {
    return (
      <>
      
      <p><span dangerouslySetInnerHTML={{__html: this.props.result.body}} /></p>
      </>
    );
  }
}

export default FaqResultRow;
