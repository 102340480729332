import React, {Component} from "react";
import {
  MDBContainer,
  MDBCollapse,
  MDBCard,
  MDBCardBody,
  MDBCollapseHeader,
  MDBIcon,
  MDBRow,
  MDBCol,
  MDBInput,
  MDBBtn,
  MDBSpinner,
  MDBFileInput,
  MDBSelect,
  MDBTooltip,
  MDBModal,
  MDBModalHeader,
  MDBModalBody
} from "mdbreact";

import Select from 'react-select'


import MDBFileupload from 'mdb-react-fileupload';

import { Editor } from '@tinymce/tinymce-react';

import DownloadActions from "./actions/DownloadActions.jsx";
import Image from "../image/Image";

import moment from 'moment';
import 'moment/locale/de';


class DownloadForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      entity: this.props.entity,
      nid: false,
      title: '',
      body: '',
      field_file: false,
      loaded: true,
      modal: false,
      downloadCategoriesFilter: this.props.searchComponent.state.downloadCategoriesFilter,
      file: ''
    }

    this.fileInputRef = React.createRef();
    this.downloadCategoryFilterRef = React.createRef();

    this._onSubmit = this._onSubmit.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
    this._onChangeHandleFile = this._onChangeHandleFile.bind(this);
    this._onFormReset = this._onFormReset.bind(this);
    this._onHandleEditorChange = this._onHandleEditorChange.bind(this);
    this._onToggleModal = this._onToggleModal.bind(this);


    //console.log(this.props.searchComponent.state.downloadCategoriesFilter);
  }


  componentDidMount() {

    let component = this;
    if(this.props.entity) {

      var downloadCategories = this.props.searchComponent.state.downloadCategories;
      var downloadCategoriesFilter = new Array();
      if(downloadCategories) {
        //console.log(component.props.entity);
        Object.entries(downloadCategories).forEach(function(item, key){
          var optionItem = {
            checked: (component.props.entity.field_download_category[0] && item[0] == component.props.entity.field_download_category[0].target_id) ? true : false,
            text: item[1].name[0].value,
            value: item[0]
          }
          downloadCategoriesFilter.push(optionItem);
          //console.log(item);
        });
      }


      this.setState(prevState => ({
        nid: this.props.entity.nid[0].value,
        title: this.props.entity.title[0].value,
        body: (this.props.entity.body[0]) ? this.props.entity.body[0].value : '',
        field_file: this.props.entity.field_file[0].target_id,
        field_download_category: (this.props.entity.field_download_category[0]) ? [this.props.entity.field_download_category[0].target_id] : '',
        downloadCategoriesFilter: downloadCategoriesFilter
      }))
    }
  }

  _onSelectChange(e) {
    var name = e.target.name;
    var value;
    if(e.target.value) {
      value = e.target.value;
    } else {
      value = null;
    }
    this.setState({
      [e.target.name]: [e.target.value],
    });
  }

  _onSubmit(e) {
    var component = this;
    e.preventDefault();



    //console.log(this.state.file);
    if(this.state.file.type != 'application/pdf') {
      //alert('Bitte PDF Datei auswählen.');
      //return;
    }


    /*
    if(!this.state.body) {
      alert('Bitte Beschreibungstext einfügen.');
      return;
    }*/


    component.props.searchComponent.setState(prevState => ({
      loaded: false,
    }))

    let values = {
      nid: this.state.nid,
      title: this.state.title,
      body: this.state.body,
      field_file: this.state.field_file,
      field_download_category: this.state.field_download_category
    }

    if(!this.state.nid) {
      DownloadActions._post(values)
        .then(response => {
          component.props.searchComponent.loadDownloadCollection();
          component._onFormReset();
          component._onToggleModal();
        });
    } else {
      DownloadActions._patch(values)
        .then(response => {
          component.props.searchComponent.loadDownloadCollection();
          component._onFormReset();
          component._onToggleModal();
        });
    }
  }

  _onInputChange(e) {
    //console.log(e);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  _onChangeHandleFile = (file) => {
    var component = this;

    this.setState({
      file: file
    });

    var fileTypes = ['jpg', 'jpeg', 'png', 'what', 'ever', 'you', 'want'];  //acceptable file types
    var extension = file.name.split('.').pop().toLowerCase();
    //console.log(extension);

    var reader = new FileReader();
    reader.addEventListener("load", function () {
         var dataString = reader.result;
         var uint8 = new Uint8Array(dataString);



         //console.log(uint8);
         DownloadActions._uploadFile(uint8, extension).then(response => {
           component.setState({
             field_file: response.data.fid[0].value
           });
         });
    }, false);


    if(file) {
      reader.readAsArrayBuffer(file);
      this.setState({
        fileUrl: URL.createObjectURL(file)
      });
    }
  }

  _onFormReset() {
    console.log(this.downloadCategoryFilterRef.current);

    if(this.downloadCategoryFilterRef.current) {
      this.downloadCategoryFilterRef.current.select.clearValue();
    }



    if(this.fileInputRef.current) {
      this.fileInputRef.current.resetPreview();
    }


    var downloadCategories = this.props.searchComponent.state.downloadCategories;
    var downloadCategoriesFilter = new Array();
    if(downloadCategories) {
      Object.entries(downloadCategories).forEach(function(item, key){
        var optionItem = {
          label: item[1].name[0].value,
          value: item[0]
        }
        downloadCategoriesFilter.push(optionItem);
      });
    }


    this.setState(prevState => ({
      loaded: true,
      nid: false,
      title: '',
      body: '',
      field_file: false,
      field_download_category: '',
      downloadCategoriesFilter: downloadCategoriesFilter,
    }))
  }

  _onHandleEditorChange = (content, editor) => {
     //console.log('Content was updated:', content);
     this.setState({
       body: content,
     });
   }


  _onToggleModal = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    let component = this;


    var defaultCategoryValue = false;

    if(this.state.nid) {
      if(this.props.searchComponent.state.downloadCategoriesFilter.find(x => x.value === this.state.field_download_category.toString())) {
        defaultCategoryValue = { label: this.props.searchComponent.state.downloadCategoriesFilter.find(x => x.value === this.state.field_download_category.toString()).label, value: this.state.field_download_category.toString() }
      }
    }

    return (

      <>

      {!this.state.entity &&
        <MDBBtn onClick={this._onToggleModal} color="primary"><MDBIcon icon="plus mr-2" /> Neuen Download erfassen</MDBBtn>
      }

      {this.state.entity &&
        <MDBBtn className="mr-2" onClick={this._onToggleModal} color="primary"><MDBIcon far icon="edit" /></MDBBtn>
      }


      <MDBModal isOpen={this.state.modal} toggle={this._onToggleModal}>
        <MDBModalHeader toggle={this._onToggleModal}>Neuen Download erfassen</MDBModalHeader>
        <MDBModalBody>
          <MDBCard className='mt-0'>
            <form id="download-add-form" onSubmit={this._onSubmit}>
              <MDBRow>
                <MDBCol size="12">
                    <MDBFileupload
                        maxFileSize="16M"
                        ref={this.fileInputRef}
                        getValue={this._onChangeHandleFile}
                        />

                </MDBCol>
                <MDBCol size="12">
                  <MDBInput outline label="Titel" name="title" id="title" onChange={this._onInputChange} value={this.state.title} required />
                </MDBCol>
              </MDBRow>



                    <MDBRow>
                      <MDBCol>
                        <Select
                          ref={this.downloadCategoryFilterRef}
                          required
                          className="react-select"
                          classNamePrefix="react-select"
                          placeholder="Kategorie"
                          options={this.state.downloadCategoriesFilter}
                          defaultValue={defaultCategoryValue}
                          isClearable={true}
                          onChange={(value) => {

                            if(value) {
                              value = value.value;
                            } else {
                              value = '';
                            }

                            this._onSelectChange({
                              target: {
                                name: 'field_download_category',
                                value: value,
                              },
                            });
                          }}
                        />
                      </MDBCol>
                    </MDBRow>

                  <hr />

                  <MDBRow>
                    <MDBCol>
                      <Editor
                       apiKey='2es7wv2n49blwza8a9hwyax3y4cov70297nz2yeuwq4w3dad'
                       value={this.state.body}
                       initialValue={this.state.body}
                       init={{
                         height: 170,
                         menubar: false,
                         plugins: [
                           'advlist autolink lists link image charmap print preview anchor',
                           'searchreplace visualblocks code fullscreen',
                           'insertdatetime media table paste code help wordcount'
                         ],
                         toolbar:
                           'undo redo | formatselect | bold italic backcolor | \
                           alignleft aligncenter alignright alignjustify | \
                           bullist numlist outdent indent | removeformat | help'
                       }}
                       onEditorChange={this._onHandleEditorChange}
                     />
                    </MDBCol>
                  </MDBRow>

               <hr />

                 <MDBRow>
                   <MDBCol>

                       {!this.state.entity &&
                         <MDBBtn color="danger" onClick={this._onFormReset} className="mr-2"><MDBIcon icon="sync mr-2" /> Zurücksetzen</MDBBtn>
                       }


                       {!this.state.field_file &&
                         <MDBBtn color="primary" type="submit" disabled><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                       }

                       {this.state.field_file &&
                         <MDBBtn color="primary" type="submit"><MDBIcon far icon="save" className="mr-2" /> Speichern</MDBBtn>
                       }

                   </MDBCol>
                 </MDBRow>


            </form>
          </MDBCard>
        </MDBModalBody>
      </MDBModal>
      </>


    );
  }
}

export default DownloadForm;
